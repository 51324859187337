import Vue from 'vue';
import VueObserveVisibility from 'vue-observe-visibility';

Vue.config.productionTip = false;

import 'bootstrap-vue/dist/bootstrap-vue.css';

import commonComponents from './components/common';
import router from './router';
import store from './store';

import NeedApp from './NeedApp.vue';
import NeedsApp from './NeedsApp.vue';
import { FETCH_PERMISSIONS, FETCH_NEEDS, FETCH_PLAN, SET_PLAN_ID } from './constants';

export default () => {
    const root = document.getElementById('needs-app');
    const roots = document.getElementsByClassName('v__need-card');

    if (root || roots) {
        Vue.use(VueObserveVisibility);
        commonComponents.forEach(component => {
            Vue.component(component.name, component);
        });
    }

    if (root) {
        /* eslint-disable no-new */
        new Vue({
            el: root,
            render: h => h(NeedsApp),
            store,
            router
        });
    }
    else if (roots.length > 0) {
        const planId = roots[0].dataset['plan'];
        store.dispatch(FETCH_PERMISSIONS);
        store.dispatch(FETCH_PLAN, {id: planId});
        store.dispatch(SET_PLAN_ID, { planId });
        store.dispatch(FETCH_NEEDS, {id: planId});
        const ids = [];
        for (let x=0; x<roots.length; x++) {
            ids.push({
                id: roots[x].id,
                needId: roots[x].dataset['need'],
                detailUrl: roots[x].dataset['detailUrl'],
            });
        }
        for (let x=0; x<ids.length; x++) {
            const { id, needId, detailUrl } = ids[x];
            /* eslint-disable no-new */
            new Vue({
                el: `#${id}`,
                render: h => h(NeedApp, {
                    props: { needId, detailUrl }
                }),
                store,
                router
            });
        }
    }
};
