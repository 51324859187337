<template>
    <div class="need-app-container">
        <div class="loading" v-if="!loaded">Loading...</div>
        <need-card v-else :detail-url="detailUrl" :need="need" :index="0" :read-only="true" @open="onShowNeed"></need-card>

        <modal v-if="showNeed" @close="onClose">
            <div slot="title" class="breadcrumb breadcrumb-transparent">
                <div class="breadcrumb-item" v-if="parent" >
                    <a href="#" @click.prevent="onShowNeed(parent)">{{ parent.title }}</a>
                </div>
                <div class="breadcrumb-item">
                    {{ showNeed.title }}
                </div>
            </div>
            <need-detail :need-id="showNeed.id" slot="body" @close="onClose" @open="onShowNeed" :read-only="true"></need-detail>

            <audit-info slot="footer" :updated-by="showNeed.updatedBy" :updated-at="showNeed.updatedAt"></audit-info>
            <supporting-file slot="footer" v-if="showNeed.supportingFile"
                        :file-name="showNeed.supportingFile"
                        :download-url="showNeed.supportingFileDownloadUrl"></supporting-file>

        </modal>
    </div>
</template>
<script>
import { FETCH_NEED } from './constants';
import { NeedCard, NeedDetail, AuditInfo, SupportingFile } from './components';

export default {
    name: 'need-app',
    props: ['needId', 'detailUrl'],
    data() {
        return {
            showNeed: null
        }
    },
    components: {
        NeedCard,
        NeedDetail,
        AuditInfo,
        SupportingFile
    },
    methods: {
        onShowNeed(need) {
            if (need && need.group && !need.loaded) {
                // lazy-load grouped need
                this.$store.dispatch(FETCH_NEED, { id: need.id }).then(() => {
                    this.showNeed = this.$store.state.needs[need.id];
                });
            }
            else {
                // retrieve exisiting Need from the store
                this.showNeed = need;
            }
        },
        onClose() {
            this.showNeed = null;
        }
    },
    computed: {
        loaded() {
            return this.need !== undefined; // && this.need.loaded;
        },
        need() {
            return this.$store.state.needs[this.needId];
        },
        parent() {
            return this.$store.state.needs[this.showNeed.group];
        }
    }
}
</script>
