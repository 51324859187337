import Quill from 'quill';

import { bindBeforeUnload } from './shortcuts';
import 'quill/dist/quill.snow.css';

import { convertLists } from './editor_shims';

const buildToolbar = function (useImageSet = false) {
    const imageOpts = ['link', 'video'];
    if (useImageSet) {
        imageOpts.splice(1, 0, 'image');
    }
    return [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [
            { align: '' },
            { align: 'center' },
            { align: 'right' },
            { align: 'justify' }
        ],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        imageOpts,
        ['clean'] // remove formatting button
    ];
};

export const toolbar = buildToolbar();
export const toolbarWithImages = buildToolbar(true);

const customImageHandler = function () {
    // ported from vue2-editor
    // prefer `.image-upload` sibling rather than a hardcoded #id selector,
    // as this allows multiple Quill instances on one page.
    $(this.quill.container).siblings('.image-upload').click();
};

const setupCustomImageHandler = (editor) => {
    // overrides Quill's default handling of images as
    // base64 encoded strings inserted into the content
    // using data URIs.
    let toolbarModule = editor.getModule('toolbar');
    toolbarModule.addHandler('image', customImageHandler);
};

const imageAdded = function (file, editor, cursorLocation, resetUploader) {
    // mimics the handleImageAdded method in RichEditor
    const formData = new FormData();
    formData.append('files', file);

    let url = $(editor.container).closest('form').data('image-upload-url');
    const id = $(editor.container).closest('form').find('#id_image_set').val();
    if (id) {
        // sets `image_set` to re-use existing pinax-images ImageSet
        formData.append('image_set', id);
        url = `${url}${id}/`;
    }


    $.ajax({
        method: 'POST',
        url: url,
        processData: false,
        contentType: false,
        cache: false,
        data: formData
    }).done(result => {
        const url = result.images[0].thumbnail;
        editor.insertEmbed(cursorLocation, 'image', url);

        $(editor.container).closest('form').find('#id_image_set').val(result.pk);

        resetUploader();
    }).fail((jqXHR) => {
        // @@@ tap into messaging framework for this alert
        console.log(`Request failed: ${jqXHR.status} (${jqXHR.statusText})`);
    });
};

const emitImageInfo = function (editor, event) {
    // port of the emtImageInfo signal from vue2-editor
    const resetUploader = function () {
        let uploader = event.target;
        uploader.value = '';
    };
    let file = event.target.files[0];
    let range = editor.getSelection();
    let cursorLocation = range.index;
    imageAdded(file, editor, cursorLocation, resetUploader);
};

const loadQuillEditor = function loadQuillEditor () {

    const instantiationAttr = 'quill-instantiated';
    const $quill_widgets = $(`.quill-widget-container[data-${instantiationAttr}=false]`);
    $quill_widgets.each((i, el) => {
        const $el = $(el);

        const imageUploadUrl = $(el).closest('form').data('image-upload-url');
        let toolbarOptions = toolbar;
        if (imageUploadUrl) {
            toolbarOptions = toolbarWithImages;
        }
        const editor = new Quill(el, {
            theme: 'snow',
            modules: { toolbar: toolbarOptions }
        });
        $el.attr(`data-${instantiationAttr}`, true);

        editor.on('text-change', function () {
            bindBeforeUnload();
        });

        if (imageUploadUrl) {
            setupCustomImageHandler(editor);

            // prefer `.image-upload` sibling rather than a hardcoded #id selector,
            // as this allows multiple Quill instances on one page.
            const inputElem = '<input class="image-upload" ref="fileInput" type="file" accept="image/*" style="display:none;">';
            $el.after(inputElem);
            $el.siblings('.image-upload').on('change', (event) => {
                emitImageInfo(editor, event);
            });
        }

        $el.closest('form').on('submit', () => {
            let content = editor.root.innerHTML;

            /**
             * when removing text from an editable the editor inserts `<p><br></p>` instead
             * of a null value.  Clean this out so we don't save it to the db.
             */
            const emptyFragments = ['<p><br></p>', '<p class=""><br></p>'];
            if (emptyFragments.indexOf(content) > -1) {
                content = '';
            }

            $el.siblings('textarea').first().val(convertLists(content));
        });
    });
};


export default loadQuillEditor;
