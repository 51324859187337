<template>
    <div class="description-instructions">
        {{ instructions }}
    </div>
</template>
<script>
export default {
    name: 'need-description-instructions',
    computed: {
        instructions () {
            return 'Consider the progresses and challenges faced for all students and for each historically underserved student group (i.e. economically disadvantaged students; black, Hispanic, Native American students; English learners; students with disabilities).';
        }
    }
}
</script>
