<template>
    <div class="needs-dashboard-container">
        <!-- TODO: Prefer classes -->
        <div class="btn-group d-flex" role="group" style="margin-top: -12px; margin-bottom: 36px;">
            <span class="nav-link btn btn-primary btn-xs w-100">
                Build &amp; Prioritize Needs
            </span>
            <a class="nav-link btn btn-outline-dark btn-xs w-100" :href="plan.prioritizedNeedsQuestionsUrl">
                Analyze Prioritized Needs
            </a>
        </div>
        <div class="needs-dashboard">
            <filter-bar></filter-bar>

            <system-messages v-if="!modalOpen"></system-messages>

        <div class="filters-actions-row mb-4">
                <active-filters></active-filters>
                <div class="actions" v-if="canEdit">
                    <copy-needs-form></copy-needs-form>
                    <button class="btn btn-sm btn-primary" :disabled="createNeed || editNeed" @click="onCreateNeed">
                        <span class="fas fa-fw fa-plus"></span>
                        Create Need
                    </button>
                </div>
            </div>
            <needs-list v-model="needs" axis="xy" @input="onNeedSort" :useDragHandle="true">
                <sortable-need-card v-for="(need, index) in needs"
                    :index="index"
                    :key="need.id"
                    :need="need"
                    @editNeed="onEditNeed(need)"
                    @groupNeeds="onGrouping"
                    @copyNeed="onCopyNeed"
                    @sendToFirst="onSendToFirst"
                    @sendToLast="onSendToLast"></sortable-need-card>
            </needs-list>

            <modal backdrop="static" v-if="createNeed || editNeed" @close="createNeed = false; editNeed = false">
                <div v-if="createNeed" slot="title">Create a Need</div>
                <div v-else slot="title">Edit Need</div>
                <need-form slot="body" :need="editNeed" @cancel="onCancelNeedForm" @needSaved="onCancelNeedForm"></need-form>
            </modal>
            <modal v-if="grouping" @close="grouping = false">
                <div slot="title"><i class="fas fa-fw fa-layer-group"></i> Create New Group</div>
                <group-need-form slot="body" @cancel="onCancelGrouping" @needsGrouped="onCancelGrouping"></group-need-form>
            </modal>
            <modal v-if="showNeed" @close="onDetailClose()">
                <div slot="title" class="breadcrumb breadcrumb-transparent">
                    <div class="breadcrumb-item" v-if="showNeedParent" >
                        <router-link :to="{name: 'need-detail', params: { needId: showNeedParent.id }}">{{ showNeedParent.title }}</router-link>
                    </div>
                    <div class="breadcrumb-item">
                        {{ showNeed.title }}
                    </div>
                </div>
                <need-detail :need-id="showNeed.id" slot="body" @close="onDetailClose" @deleted="onDetailClose" @editNeed="onEditNeed" @copyNeed="onCopyNeed"></need-detail>

                <audit-info slot="footer" :updated-by="showNeed.updatedBy" :updated-at="showNeed.updatedAt"></audit-info>
                <supporting-file slot="footer" v-if="showNeed.supportingFile"
                            :file-name="showNeed.supportingFile"
                            :download-url="showNeed.supportingFileDownloadUrl"></supporting-file>

            </modal>
        </div>
    </div>
</template>
<script>
import CopyNeedsForm from '../copy/CopyNeedsForm.vue';

import NeedsList from './NeedsList.vue';
import SortableNeedCard from './SortableNeedCard.vue';
import NeedDetail from './NeedDetail.vue';
import FilterBar from '../filter/FilterBar.vue';
import ActiveFilters from '../filter/ActiveFilters.vue';
import GroupNeedForm from '../group-form/GroupNeedForm.vue';
import NeedForm from '../need-form/NeedForm.vue';
import AuditInfo from './AuditInfo.vue';
import SupportingFile from './SupportingFile.vue';
import { MESSAGE, FETCH_NEED, GROUP_SELECTED_NEEDS, COPY_NEED, SET_NEED_ORDER } from '../../constants';

export default {
    name: 'needs-dashboard',
    data() {
        return {
            grouping: false,
            createNeed: false,
            editNeed: null,
            needs: []
        }
    },
    created() {
        this.needs = [...this.filteredNeeds];
    },
    watch: {
        filteredNeeds() {
            this.needs = [...this.filteredNeeds];
        },
    },
    computed: {
        canEdit() {
            return (this.$store.state.permissions.includes('plan.edit_need'));
        },
        modalOpen() {
            return !!(this.showNeed || this.grouping || this.createNeed || this.editNeed);
        },
        filteredNeeds() {
            return this.$store.getters.filteredNeeds;
        },
        selectedNeeds() {
            return this.$store.state.selectedNeeds.map(id => this.$store.state.needs[id]);
        },
        showNeed() {
            if (this.$route.params.needId) {
                let shownNeed = this.$store.state.needs[this.$route.params.needId]
                if (shownNeed && !shownNeed.loaded) {
                    // lazy-load grouped need
                    shownNeed = this.$store.dispatch(FETCH_NEED, { id: shownNeed.id });
                }
                return shownNeed;
            }
        },
        showNeedParent() {
            return this.showNeed ? this.$store.state.needs[this.showNeed.group] : null;
        },
        plan() {
            return this.$store.getters.currentPlan;
        }
    },
    methods: {
        setTitle() {
            document.title = `${this.plan.label} - Analyze Needs`
        },
        onCopyNeed(need, done) {
            this.$store
                .dispatch(COPY_NEED, { id: need.id, planId: need.plan })
                .then(() => {
                    const needId = this.$store.state.needsOrder
                        .filter(id => this.$store.state.needs[id].copiedFrom === need.id)[0];
                    done(this.$store.state.needs[needId]);
                });
        },
        onNeedSort(list) {
            const visiblePks = list.map(n => n.id);
            const pkList = [
                ...visiblePks,
                ...this.$store.state.needsOrder.filter(id => visiblePks.indexOf(id) === -1)
            ];
            this.$store.dispatch(SET_NEED_ORDER, { id: this.$store.state.planId, pkList });
        },
        onSendToFirst(need) {
            const pkList = [
                need.id,
                ...this.$store.state.needsOrder.filter(id => id !== need.id)
            ];
            this.$store.dispatch(SET_NEED_ORDER, { id: this.$store.state.planId, pkList });
        },
        onSendToLast(need) {
            const pkList = [
                ...this.$store.state.needsOrder.filter(id => id !== need.id),
                need.id
            ];
            this.$store.dispatch(SET_NEED_ORDER, { id: this.$store.state.planId, pkList });
        },
        onDetailClose() {
            this.$router.push({name: 'needs-dashboard', params: { planId: this.showNeed.plan }})
            this.setTitle();
        },
        onCreateNeed() {
            this.createNeed = true;
        },
        onCancelNeedForm() {
            this.createNeed = false;
            this.editNeed = null;
            this.setTitle();
        },
        onCancelGrouping() {
            this.grouping = false;
            this.setTitle();
        },
        onEditNeed(need) {
            if (this.showNeed) {
                this.$router.push({name: 'needs-dashboard', params: { planId: this.showNeed.plan }})
            }
            this.editNeed = need;
        },
        onGrouping() {
            if (this.selectedNeeds.filter(n => n.isGroup).length > 0) {
                this.$store.dispatch(GROUP_SELECTED_NEEDS, {title: '', description: ''});
            }
            else {
                this.grouping = true;
            }
        }
    },
    components: {
        FilterBar,
        CopyNeedsForm,
        GroupNeedForm,
        SortableNeedCard,
        NeedForm,
        ActiveFilters,
        NeedsList,
        NeedDetail,
        AuditInfo,
        SupportingFile
    }
}
</script>
