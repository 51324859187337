<template>
    <div class="group-need-list">
        <grouped-need v-for="need in needs" v-if="need" :key="need.id" :read-only="readOnly" :need="need" @open="$emit('open', need)"></grouped-need>
        <a class="view-more" href="" @click.prevent="$emit('viewMore')" v-if="!showAll && leftOver.length > 0">
            View {{ leftOver.length }} more...
        </a>
    </div>
</template>
<script>
import GroupedNeed from './GroupedNeed.vue';
import {GroupedNeedsMixin} from '../../mixins.js';

export default {
    name: 'grouped-need-list',
    props: ['groupedNeeds', 'showAll', 'readOnly'],
    mixins: [GroupedNeedsMixin],
    components: {
        GroupedNeed
    }
}
</script>
