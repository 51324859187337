function formatResult (title) {
    if (!title.id) {
        return title.text;
    }
    // NOTE: Return the content of `title` from the lever
    return $(`${title.text}`)[0].innerText;
}

function formatSelection (title) {
    if (!title.id) {
        return title.text;
    }
    // NOTE: Return the content of `title` and `description` from the lever
    return $(`${title.text}`);
}

const setupTurnaroundPlanGoalFields = function setupTurnaroundPlanGoalFields () {
    const $predefinedTitleElem = $('body.smart-goal-form #id_predefined_title');
    if ($predefinedTitleElem.length) {
      $predefinedTitleElem.select2({
        templateResult: formatResult,
        templateSelection: formatSelection,
      });
    }
};

export default setupTurnaroundPlanGoalFields;
