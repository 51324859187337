export const FETCH_PLAN = 'fetchPlan';
export const FETCH_PLANS = 'fetchPlans';
export const FETCH_NEED = 'fetchNeed';
export const FETCH_NEEDS = 'fetchNeeds';
export const FETCH_PERMISSIONS = 'fetchPermissions';
export const FETCH_SECTIONS = 'fetchSections';
export const SET_LOADED = 'setLoaded';
export const FILTER_PRIORITY = 'filterPriority';
export const FILTER_ARCHIVED = 'filterArchived';
export const FILTER_SECTIONS = 'filterSections';
export const SEARCH = 'search';
export const RESET_FILTERS = 'resetFilters';
export const SET_PRIORITY = 'setPriority';
export const SET_ARCHIVED = 'setArchived';
export const SET_NON_PRIORITY = 'setNonPriority';
export const SET_UNARCHIVED = 'setUnarchived';
export const RESET_NEED = 'resetNeed';
export const GROUP_SELECTED_NEEDS = 'groupSelectedNeeds';
export const UNGROUP_NEED = 'ungroupNeed';
export const TOGGLE_SELECT_NEED = 'toggleSelectNeed';
export const SELECT_ALL_NEEDS = 'selectAllNeeds';
export const RESET_SELECTED_NEEDS = 'resetSelectedNeeds';
export const SAVE_NEED = 'saveNeed';
export const CREATE_NEED = 'createNeed';
export const SET_PLAN_ID = 'setPlanId';
export const DELETE_NEED = 'deleteNeed';
export const ERROR = 'error';
export const MESSAGE = 'message';
export const CLEAR_ERROR = 'clearError';
export const CLEAR_MESSAGE = 'clearMessage';
export const COPY_NEED = 'copyNeed';
export const COPY_NEEDS = 'copyNeeds';
export const SET_NEED_ORDER = 'setNeedOrder';
export const MAX_TITLE_LENGTH = 255;
export const SYSTEM_USER_EMAIL = 'inform-tn-system@sip.eldarion.com';
