<template>
    <div class="grouped-needs-list">
        <grouped-need-card v-for="(need, index) in needs" v-if="need"
            :key="need.id"
            :need="need"
            :index="index"
            :read-only="readOnly"
            :reference-only="referenceOnly"
             @open="$emit('open', need)"
        >
        </grouped-need-card>
        <div v-for="(index, _) in extras" :key="index" class="need-filler"></div>
    </div>
</template>
<script>
import GroupedNeedCard from './GroupedNeedCard.vue';
import {GroupedNeedsMixin} from '../../mixins.js';


export default {
    name: 'grouped-need-card-list',
    props: ['groupedNeeds', 'readOnly', 'referenceOnly'],
    mixins: [GroupedNeedsMixin],
    components: {
        GroupedNeedCard
    },
    computed: {
        extras() {
            return new Array(this.needs.length % 3);
        }
    }
}
</script>
