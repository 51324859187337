import {
    FETCH_NEED,
    FETCH_NEEDS,
    FETCH_PLAN,
    FETCH_PLANS,
    SET_LOADED,
    FILTER_ARCHIVED,
    FILTER_PRIORITY,
    FILTER_SECTIONS,
    SEARCH,
    RESET_NEED,
    RESET_FILTERS,
    GROUP_SELECTED_NEEDS,
    RESET_SELECTED_NEEDS,
    TOGGLE_SELECT_NEED,
    FETCH_PERMISSIONS,
    FETCH_SECTIONS,
    SET_PLAN_ID,
    DELETE_NEED,
    ERROR,
    CLEAR_ERROR,
    CLEAR_MESSAGE,
    MESSAGE,
    SET_NEED_ORDER,
    UNGROUP_NEED,
    SELECT_ALL_NEEDS,
    COPY_NEEDS
} from '../constants';

const mapReducer = (map, obj, loaded) => {
    obj.loaded = loaded;
    map[obj.id] = obj;
    return map;
};
const mapLoad = (items, loaded) => items.reduce((map, obj) => mapReducer(map, obj, loaded), {});

const getErrorMessages = (error, default_message) => {
    if (error.data.errors) {
        return error.data.errors;
    }
    return [default_message];
};

export default {
    [ERROR]: (state, {error, default_message} ) => {
        const e = {
            status: error.status,
            statusText: error.statusText,
            messages: getErrorMessages(error, default_message),
            fromUrl: error.config.url,
            fromMethod: error.config.method
        };
        state.errors = [...state.errors, e];
    },
    [CLEAR_ERROR]: (state, index) => {
        state.errors.splice(index, 1);
    },
    [MESSAGE]: (state, message) => {
        state.messages = [...state.messages, message];
    },
    [CLEAR_MESSAGE]: (state, index) => {
        state.messages.splice(index, 1);
    },
    [SET_PLAN_ID]: (state, planId) => state.planId = planId,
    [FETCH_PERMISSIONS]: (state, data) => state.permissions = data,
    [FETCH_PLANS]: (state, data) => {
        state.plansOrder = data.plans.map(p => p.id);
        state.plans = {
            ...state.plans,
            ...mapLoad(data.plans, true)
        };
    },
    [FETCH_PLAN]: (state, data) => {
        state.plans = {
            ...state.plans,
            [data.id]: data,
        };
    },
    [FETCH_SECTIONS]: (state, data) => {
        state.sectionOrder = data.sections.map(s => s.id);
        state.sections = mapLoad(data.sections, true);
    },
    [FETCH_NEEDS]: (state, data) => {
        state.needsOrder = data.needs.map(n => n.id);
        state.needs = mapLoad(data.needs, false);
    },
    [COPY_NEEDS]: (state, data) => {
        state.needs = {
            ...state.needs,
            ...mapLoad(data.needs, true)
        };
    },
    [SET_NEED_ORDER]: (state, data) => {
        state.needsOrder = data.needs.map(n => n.id);
    },
    [FETCH_NEED]: (state, data) => {
        data.loaded = true;
        state.needs = {
            ...state.needs,
            [data.id]: data
        };
        if (state.needsOrder.indexOf(data.id) === -1) {
            state.needsOrder.push(data.id);
        }
    },
    [GROUP_SELECTED_NEEDS]: (state, data) => {
        // If a group is created, then insert it in the location of the first selected need
        if (data.groupCreated) {
            const index = state.selectedNeeds
                .map(id => state.needsOrder.indexOf(id))
                .sort()[0];
            const original = [...state.needsOrder];
            original.splice(index, 0, data.group);
            state.needsOrder = [...original];
        }
        // if group is appended to, then put the selected needs (which are now
        // grouped) at the end of the needsOrder, so that they sort to bottom of
        // grouped needs
        else {
            const indicies = state.selectedNeeds
                .filter(id => id !== data.group)
                .map(id => state.needsOrder.indexOf(id));
            const original = [...state.needsOrder];
            indicies.forEach(i => {
                original.push(original.splice(i, 1)[0]);
            });
            state.needsOrder = [...original];
        }
        state.needs = {
            ...state.needs,
            ...mapLoad(data.needs, true)
        };
    },
    [UNGROUP_NEED]: (state, data) => {
        // data.needs[0] is the group that data.needs[1] is no longer attached to
        if (data.needs[0].id) { // Protect against race condition
            const index = state.needsOrder.indexOf(data.needs[0].id);
            const other = state.needsOrder.indexOf(data.needs[1].id);
            const original = [...state.needsOrder];
            original.splice(other, 1);
            original.splice(index + 1, 0, data.needs[1].id);
            state.needsOrder = [...original];
            state.needs = {
                ...state.needs,
                ...mapLoad(data.needs, true)
            };
        }
    },
    [RESET_SELECTED_NEEDS]: state => {
        state.selectedNeeds = [];
        state.selectedGroupNeeds = [];
    },
    [TOGGLE_SELECT_NEED]: (state, {id, isGroup}) => {
        const selectedIndex = state.selectedNeeds.indexOf(id);
        if (selectedIndex > -1) {
            state.selectedNeeds.splice(selectedIndex, 1);
        }
        else {
            state.selectedNeeds.push(id);
        }

        if (isGroup) {
            const selectedGroupIndex = state.selectedGroupNeeds.indexOf(id);
            if (selectedGroupIndex > -1) {
                state.selectedGroupNeeds.splice(selectedGroupIndex, 1);
            }
            else {
                state.selectedGroupNeeds.push(id);
            }
        }
    },
    [SELECT_ALL_NEEDS]: (state, needs) => {
        state.selectedNeeds = [];
        state.selectedGroupNeeds = [];

        needs.forEach(need => {
            if (need.isGroup) {
                state.selectedGroupNeeds.push(need.id);
            }
            state.selectedNeeds.push(need.id);
        });
    },
    [RESET_NEED]: (state, id) => state.needs[id].loaded = false,
    [SET_LOADED]: state => state.loaded = true,
    [FILTER_ARCHIVED]: (state, archived) => state.filters.archived = archived,
    [FILTER_PRIORITY]: (state, priority) => state.filters.priority = priority,
    [FILTER_SECTIONS]: (state, sections) => {
        state.filters.sections = sections;
    },
    [SEARCH]: (state, term) => state.search = term,
    [RESET_FILTERS]: state => {
        state.filters = {
            archived: false,
            priority: null,
            sections: []
        };
        state.search = '';
    },
    [DELETE_NEED]: (state, id) => {
        const { [id]: deleted, ...newNeeds } = state.needs;
        state.lastDeletedNeed = deleted;
        state.needs = newNeeds;
        state.needsOrder.splice(state.needsOrder.indexOf(id), 1);
    }
};
