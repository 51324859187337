<template>
    <div class="active-filters">
        <span @click="onRemoveFilter('archived')" class="archived" v-if="filters.archived">Archived</span>
        <span @click="onRemoveFilter('active')" class="active" v-if="filters.archived === false">Active</span>
        <span @click="onRemoveFilter('priority')" class="priority" v-if="filters.priority">Priority</span>
        <span @click="onRemoveFilter('non-priority')" class="non-priority" v-if="filters.priority === false">Non-Priority</span>
        <span @click="onRemoveFilter(section)" class="section" v-for="section in filters.sections" :key="section">{{ section || 'Groups' }}</span>
    </div>
</template>
<script>
import { FILTER_PRIORITY, FILTER_ARCHIVED, FILTER_SECTIONS } from '../../constants';
export default {
    name: 'active-filters',
    computed: {
        filters() {
            return this.$store.state.filters;
        }
    },
    methods: {
        onRemoveFilter(kind) {
            if (kind === 'archived' || kind === 'active') {
                this.$store.dispatch(FILTER_ARCHIVED, { archived: null });
            }
            else if (kind === 'priority' || kind === 'non-priority') {
                this.$store.dispatch(FILTER_PRIORITY, { priority: null });
            }
            else {
                const sections = [
                    ...this.$store.state.filters.sections
                ];
                sections.splice(sections.indexOf(kind), 1);
                this.$store.dispatch(FILTER_SECTIONS, { sections });
            }
        }
    }
}
</script>
