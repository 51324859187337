<template>
    <div class="lazy-image" @click="$emit('click')" :class="{'with-height': !loaded, 'has-error': error}">
        <loader :loaded="loaded || error"></loader>
        <transition name="fade">
            <img :class="classes" :src="src" @load="onLoaded" @error="onError" v-show="loaded" />
        </transition>
    </div>
</template>
<script>
export default {
    name: 'lazy-image',
    props: ['classes', 'src'],
    methods: {
        onLoaded() {
            this.loaded = true;
        },
        onError() {
            this.error = true;
        }
    },
    data() {
        return {
            error: false,
            loaded: false
        }
    }
}
</script>
