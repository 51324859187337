import typeahead from 'corejs-typeahead'; // eslint-disable-line no-unused-vars
import Bloodhound from 'corejs-typeahead/dist/bloodhound';

import { prefetchDefaults, showAllResultsForEngine, sorterDefaults } from './typeahead-common';

const setupSourceAutoComplete = function setupSourceAutoComplete () {
    if ($('body.idea-sharing').length > 0) {
        const $sourcesSelect = $('.sources-select');
        $sourcesSelect.each((i, el) => {
            const $el = $(el);
            const engine = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                identify: obj => obj.id,
                prefetch: prefetchDefaults($el.data('district-choices-url')),
                sorter: sorterDefaults
            });
            $el.siblings().closest('.typeahead').typeahead({
                hint: true,
                highlight: true,
                minLength: 0
            },
                {
                    name: 'entities',
                    source: showAllResultsForEngine(engine),
                    display: 'name',
                    limit: engine.all().length,
                    templates: {
                        suggestion: obj => {
                            return `<div><div class="tt-entity">${obj.name}</div></div>`;
                        }
                    }
                }).bind('typeahead:select', function () {
                    document.getElementById('idea-sharing-form').submit();
                });
        });

        $('idea-sharinginput[name=filter_by]').change(function () {
            if ($('#id_filter_by_1').is(':checked') || $('#id_filter_by_2').is(':checked')) {
                document.getElementById('district-typeahead').style.display = 'block';
                // if the user switches to comparable to and the typeahead is not empty, submit
                if ($('#id_filter_by_2').is(':checked') && document.getElementById('district-typeahead') != null) {
                    document.getElementById('idea-sharing-form').submit();
                }
                // same as above but didn't want a extremely long conditional
                if ($('#id_filter_by_1').is(':checked') && document.getElementById('district-typeahead') != null) {
                    document.getElementById('idea-sharing-form').submit();
                }
            }

            if ($('#id_filter_by_0').is(':checked')) {
                document.getElementById('district-typeahead').style.display = 'none';
                $('#id_district').val('');
                document.getElementById('idea-sharing-form').submit();
            }
        });

        $('input[type=checkbox]').change(function () {
            var desired_value = $(this).prop('checked');
            var checkboxes = $(this).closest('.checkbox-container').find('input[type=checkbox]');
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = false;
            }
            $(this).prop('checked', desired_value);
        });


        if (document.getElementById('clear-district')) {
            document.getElementById('clear-district').onclick = function clearfield () {
                document.getElementById('id_district').value = '';
                document.getElementById('id_filter_by_0').checked = true;
                document.getElementById('district-typeahead').style.display = 'none';
                document.getElementById('idea-sharing-form').submit();
            };
        }
    }
};

export default setupSourceAutoComplete;
