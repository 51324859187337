<template>
    <span title="Remove need from the group" v-if="canUngroup && !readOnly" class="ungroup-need" @click="ungroup"><i class="fas fa-fw fa-times"></i></span>
</template>
<script>
import { TOGGLE_SELECT_NEED, UNGROUP_NEED } from '../../constants';

export default {
    name: 'ungroup-need',
    props: ['need', 'readOnly', 'draft'],
    computed: {
        canEdit() {
            return (this.$store.state.permissions.includes('plan.edit_need'));
        },
        canUngroup() {
            return this.canEdit && !this.need.immutable;
        },
    },
    methods: {
        ungroup() {
            if (this.draft) {
                this.$store.dispatch(TOGGLE_SELECT_NEED, { id: this.need.id, isGroup:this.need.isGroup });
            }
            else {
                this.$store.dispatch(UNGROUP_NEED, { id: this.need.id });
            }
        }
    }
}
</script>
