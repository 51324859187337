import setupEntityAutoComplete from './entities';

const setupPublicPlanSchoolYearSearch = () => {
    const $body = $('.public-planning');
    $body.find('select.public-plan-school-year').change(function () {
        const url = $('.school-year-url').data('url').replace('0', this.value);
        $.ajax({
            url: url,
            method: 'GET',
            success: data => {
                $('.public-plan-search-container').html(data.html);
                setupEntityAutoComplete();
            }
        });
    });
};

const setupPlanSchoolYearToggle = () => {
    const $body = $('.public-planning.planning-summary, .planning, .dashboard');
    $body.find('select.school-year-select').change(function () {
        window.location = this.value;
    });
};

export {
    setupPublicPlanSchoolYearSearch,
    setupPlanSchoolYearToggle
};
