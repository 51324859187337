const setupExportModals = () => {
    $('.plan-export-button').on('click', function () {
        const $this = $(this);
        // toggle modal with click handler to work around
        // button being stuck in active state after the
        // modal is closed
        $($this.data('modal-target')).modal();
    });

    $('.plan-export-modal').on('shown.bs.modal', function () {
        const url = $(this).data('url');
        // @@@ change to polling/async response via #554
        // https://github.com/eldarion-client/tdoe/issues/554
        $.ajax({
            method: 'post',
            url: url,
        }).done(responseData => {
            $(this).modal('hide');
            window.location.href = responseData.location;
        });
    });
};

export default setupExportModals;
