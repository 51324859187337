<template>
    <router-link v-if="detailModal" :to="{name: 'need-detail', params: { needId: needId }}">{{ title }}</router-link>
    <a href="#" v-else @click.prevent="$emit('open')">{{ title }}</a>
</template>
<script>
export default {
    name: 'title-link',
    props: ['needId', 'title'],
    computed: {
        detailModal() {
            return this.$route.name !== null;
        }
    }
}
</script>
