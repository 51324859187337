<template>
    <div class="section-header">
        <div class="title">
            <i></i>
            {{ sectionName }}
        </div>
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'section-header',
    props: ['sectionName']
}
</script>
