<template>
    <div
        class="box need"
        v-observe-visibility="visibilityChanged"
        v-bind:class="[
            sectionClass,
            archiveClass,
            priorityClass,
            selectedClass,
            {'need-reference': referenceOnly}
        ]"
    >
        <div class="box-body">
            <section-header v-if="sectionName && !referenceOnly" :section-name="sectionName" :edit-group="false">
                <slot name="section-header-move-menu"></slot>
                <slot name="ungroup-need"></slot>
            </section-header>

            <need-content
                :detail-url="detailUrl"
                :group-id="need.group"
                :is-group="need.isGroup"
                :needId="need.id"
                :title="title"
                :description="description"
                :image-url="imageUrl"
                :thumbnail-url="thumbnailUrl"
                :reference-only="referenceOnly"
                :hide-lightbox="shouldHideLightbox"
                @open="$emit('open', need)">
                <slot name="content-move-menu"></slot>
            </need-content>

            <grouped-need-list v-if="showGroupedNeeds" :read-only="readOnly" @open="g => $emit('open', g)" :grouped-needs="groupedNeeds" @viewMore="onViewMore"></grouped-need-list>

            <audit-info :updated-by="updatedBy" :updated-at="updatedAt"></audit-info>

            <supporting-file v-if="supportingFile"
                :file-name="supportingFile"
                :download-url="supportingFileDownloadUrl"></supporting-file>

            <action-bar v-if="canEdit && !readOnly"
                :can-toggle-priority="canTogglePriority"
                :can-group-selections="canGroupSelections"
                :priority="priority"
                :archived="archived"
                :immutable="immutable"
                :planStatus="planStatus"
                :selected="selected"
                :totalSelected="totalSelected"
                :changes-required="changesRequired"
                :loading="needActionLoading"
                @editNeed="$emit('editNeed', need)"
                @selectNeed="onSelectedToggle"
                @groupNeeds="$emit('groupNeeds')"
                @togglePriority="onTogglePriority"
                @toggleArchive="onToggleArchive"
                @copyNeed="onCopyNeed"></action-bar>
            <priority-bar v-else-if="!readOnly" :priority="priority"></priority-bar>
        </div>
    </div>
</template>
<script>
import { FETCH_NEED, SET_NON_PRIORITY, SET_PRIORITY, SET_UNARCHIVED, SET_ARCHIVED, TOGGLE_SELECT_NEED, MESSAGE } from '../../constants';

import ActionBar from './ActionBar.vue';
import PriorityBar from './PriorityBar.vue';
import AuditInfo from './AuditInfo.vue';
import GroupedNeedList from './GroupedNeedList.vue';
import NeedContent from './NeedContent.vue';
import SectionHeader from './SectionHeader.vue';
import SupportingFile from './SupportingFile.vue';

export default {
    name: 'need-card',
    props: ['need', 'readOnly', 'referenceOnly', 'detailUrl'],
    data() {
        return {
            skipNeedFetch: false,
            needActionLoading: false
        }
    },
    watch: {
        need() {
            this.skipNeedFetch = false;
        }
    },
    mounted() {
        const container = this.$el.closest('.need-questions-container');
        if (container) {
            this.scrollToAnswer(container);
        }
    },
    methods: {
        scrollToAnswer(container) {
            const anchorName = location.hash.slice(1,) ;
            const matches = container.querySelectorAll(`[name="${anchorName}"]`);
            if (matches.length > 0) {
                document.querySelector(`[name="${anchorName}"]`).scrollIntoView();
            }
        },
        onViewMore() {
            if (this.detailModal) {
                this.$router.push({name: 'need-detail', params: { needId: this.need.id }});
            }
            else {
                this.$emit('open', this.need);
            }
        },
        onCopyNeed() {
            this.needActionLoading = true;
            this.$emit('copyNeed', this.need, newNeed => {
                this.needActionLoading = false;
                this.$router.push({name: 'need-detail', params: { needId: newNeed.id } });
                this.$store.dispatch(MESSAGE, { message: "Need successfully copied"});
            });
        },
        onToggleArchive() {
            this.skipNeedFetch = true;
            this.needActionLoading = true;
            const action = this.archived ? SET_UNARCHIVED : SET_ARCHIVED;
            this.$store.dispatch(action, { id: this.need.id }).then(() => {
                this.needActionLoading = false;
            });
        },
        onTogglePriority() {
            this.skipNeedFetch = true;
            this.needActionLoading = true;
            const action = this.priority ? SET_NON_PRIORITY : SET_PRIORITY;
            this.$store.dispatch(action, { id: this.need.id }).then(() => {
                this.needActionLoading = false;
            });
        },
        ungroupNeed() {
            console.log(`dispatch an ungroup action for ${this.need.id}`);
        },
        visibilityChanged(visible) {
            // TODO: Reduce duplicate queries by batching API requests
            // within Needs app;
            // GraphQL or a field-aware API might help a _lot_ here!
            if (visible && !this.loaded && !this.skipNeedFetch) {
                this.$store.dispatch(FETCH_NEED, { id: this.need.id });
            }
        },
        onSelectedToggle() {
            this.$store.dispatch(TOGGLE_SELECT_NEED, { id: this.need.id, isGroup: this.need.isGroup });
        }
    },
    computed: {
        shouldHideLightbox() {
            return this.referenceOnly || this.need.group;
        },
        selected() {
            return this.$store.state.selectedNeeds.indexOf(this.need.id) > -1;
        },
        totalSelected() {
            return this.$store.state.selectedNeeds.length;
        },
        title() {
            return this.need.title;
        },
        sectionName() {
            return this.need.sectionName;
        },
        imageUrl() {
            return this.need.imageUrl;
        },
        thumbnailUrl() {
            return this.need.thumbnailUrl;
        },
        canGroupSelections () {
            return this.$store.state.selectedGroupNeeds.length <= 1;
        },
        canTogglePriority() {
            return this.need.canTogglePriority;
        },
        changesRequired() {
            return this.need.changesRequired;
        },
        planStatus() {
            return this.need.planStatus;
        },
        immutable() {
            return this.need.immutable;
        },
        archived() {
            return this.need.archived;
        },
        priority() {
            return this.need.priority;
        },
        canEdit() {
            return (this.$store.state.permissions.includes('plan.edit_need'));
        },
        updatedBy() {
            return this.need.updatedBy;
        },
        updatedAt() {
            return this.need.updatedAt;
        },
        description() {
            return this.need.description;
        },
        supportingFile() {
            return this.need.supportingFile;
        },
        supportingFileDownloadUrl() {
            return this.need.supportingFileDownloadUrl;
        },
        loaded() {
            return this.need.loaded;
        },
        showGroupedNeeds() {
            return this.loaded && this.groupedNeeds.length > 0;
        },
        groupedNeeds() {
            return this.need.groupedNeeds.map(id => {
                if (this.$store.state.needs[id] === undefined) {
                    this.$store.dispatch(FETCH_NEED, { id });
                }
                return id;
            });
        },
        sectionClass() {
            return `section-${this.need.sectionSlug}`;
        },
        archiveClass() {
            return this.archived ? 'archived' : '';
        },
        priorityClass() {
            return this.priority ? 'priority' : '';
        },
        selectedClass() {
            return this.selected ? 'selected' : '';
        },
        detailModal() {
            return this.$route.name !== null;
        }
    },
    components: {
        ActionBar,
        PriorityBar,
        AuditInfo,
        GroupedNeedList,
        NeedContent,
        SectionHeader,
        SupportingFile
    }
}
</script>
