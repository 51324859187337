<template>
    <div class="needs-list" v-if="loaded">
        <slot></slot>
        <div v-for="(index, _) in extras" :key="index" class="need-filler"></div>
    </div>
</template>
<script>
import { ContainerMixin } from 'vue-slicksort';

export default {
    name: 'needs-list',
    computed: {
        loaded() {
            return this.$store.state.loaded;
        },
        extras() {
            return new Array(this.value.length % 3);
        }
    },
    mixins: [ContainerMixin],
}
</script>
