<template>
    <nav class="needs-filter-bar">
        <div class="filters-nav">
            <span class="filters-label"><i class="fas fa-fw fa-filter"></i> <span v-if="filtered">{{ needsCount }}</span><strong v-else>Filters</strong></span>

            <filter-toggle-menu :active="filterMenuActive === 'archive'" :items="archiveFilterItems" @input="onArchivedFilter" :value="archivedFilters" @toggleMenu="onToggleMenu('archive')">
                <span v-if="filters.archived">Archived</span>
                <span v-if="filters.archived === false">Active</span>
                <span v-if="filters.archived === null">All</span>
                <span class="fas fa-fw fa-caret-down"></span>
            </filter-toggle-menu>
            <filter-toggle-menu :active="filterMenuActive === 'priority'" :items="priorityFilterItems" @input="onPriorityFilter" :value="priorityFilters" @toggleMenu="onToggleMenu('priority')">
                <span v-if="filters.priority">Priority</span>
                <span v-if="filters.priority === false">Non-Priority</span>
                <span v-if="filters.priority === null">Any Priority</span>
                <span class="fas fa-fw fa-caret-down"></span>
            </filter-toggle-menu>
            <filter-toggle-menu :multiple="true" :active="filterMenuActive === 'sections'" :items="sectionFilterItems" @input="onSectionFilter" :value="filters.sections" @toggleMenu="onToggleMenu('sections')">
                <span v-if="filters.sections.length === 1">{{ filters.sections[0] }}</span>
                <span v-if="filters.sections.length > 1">Multiple Sections</span>
                <span v-if="filters.sections.length === 0">All Sections</span>
                <span class="fas fa-fw fa-caret-down"></span>
            </filter-toggle-menu>

            <span class="reset-filters" v-if="filtered" @click="onResetFilters"><i class="fas fa-fw fa-ban"></i>Reset</span>
        </div>

        <div class="search-ordering">
            <div class="search input-group">
                <input v-model="localSearch" class="form-control" placeholder="Search...">
            </div>
        </div>
    </nav>
</template>
<script>
import countBy from 'lodash.countby';
import { RESET_FILTERS, FILTER_PRIORITY, FILTER_ARCHIVED, FILTER_SECTIONS, SEARCH } from '../../constants';

import FilterToggleMenu from './FilterToggleMenu.vue';

export default {
    name: 'filter-bar',
    components: {
        FilterToggleMenu
    },
    data() {
        return {
            localSearch: '',
            filterMenuActive: null,
        }
    },
    methods: {
        onToggleMenu(menu) {
            this.filterMenuActive = this.filterMenuActive === menu ? null : menu;
        },
        onResetFilters() {
            this.$store.dispatch(RESET_FILTERS);
        },
        onArchivedFilter(choices) {
            const archived = choices.length === 1 ? choices[0] === 'archived' : null;
            if (this.filters.archived === archived) {
                this.$store.dispatch(FILTER_ARCHIVED, { archived: null });
            } else {
                this.$store.dispatch(FILTER_ARCHIVED, { archived });
            }
        },
        onPriorityFilter(choices) {
            const priority = choices.length === 1 ? choices[0] === 'priority' : null;
            if (this.filters.priority === priority) {
                this.$store.dispatch(FILTER_PRIORITY, { priority: null });
            } else {
                this.$store.dispatch(FILTER_PRIORITY, { priority });
            }
        },
        onSectionFilter(choices) {
            this.$store.dispatch(FILTER_SECTIONS, { sections: choices });
        }
    },
    watch: {
        localSearch() {
            this.$store.dispatch(SEARCH, { term: this.localSearch } );
        }
    },
    computed: {
        archivedFilters() {
            return this.filters.archived === null ? [] : this.filters.archived ? ['archived'] : ['active'];
        },
        archiveFilterItems() {
            return [
                {label: 'Archived', value: 'archived'},
                {label: 'Active', value: 'active'}
            ]
        },
        priorityFilters() {
            return this.filters.priority === null ? [] : this.filters.priority ? ['priority'] : ['non-priority'];
        },
        priorityFilterItems() {
            return [
                {label: 'Priority', value: 'priority'},
                {label: 'Non-Priority', value: 'non-priority'}
            ]
        },
        sectionFilterItems() {
            return Object.keys(this.sectionChoices).map(section => {
                return {label: section || 'Groups', value: section};
            });
        },
        needsCount() {
            return this.$store.getters.filteredNeeds.length;
        },
        filtered() {
            return this.$store.getters.filtered;
        },
        filters() {
            return this.$store.state.filters;
        },
        needs() {
            return this.$store.state.needsOrder.map(id => this.$store.state.needs[id]);
        },
        sectionChoices() {
            return countBy(this.needs, o => o.sectionName);
        },
        archivedChoices() {
            return countBy(this.needs, o => o.archived);
        },
        priorityChoices() {
            return countBy(this.needs, o => o.priority);
        }
    }
}
</script>
