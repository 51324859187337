import LazyImage from './LazyImage.vue';
import Loader from './Loader.vue';
import Modal from './Modal.vue';
import RichEditor from './RichEditor.vue';
import PrettyDate from './PrettyDate.vue';
import SystemMessages from './SystemMessages.vue';
import LoadingIndicator from './LoadingIndicator.vue';
import LightBox from './LightBox.vue';

export default [
    LazyImage,
    Loader,
    Modal,
    RichEditor,
    PrettyDate,
    SystemMessages,
    LoadingIndicator,
    LightBox
];
