<script>
  import bCollapse from 'bootstrap-vue/es/components/collapse/collapse';
  export default {
    /*
     Wraps bCollapse with an event listener keyed off of the `groupKey` property.

     Event emitters send {show: true|false} to show/hide all collapses in the group.
    */
    name: 'GroupedCollapse',
    extends: bCollapse,
    props: {
      groupKey: {
        type: [String, Number],
        required: true
      }
    },
    computed: {
      collapseGroupEvent () {
        return 'TOGGLE_COLLAPSE_GROUP_' + this.groupKey;
      }
    },
    created() {
      if (this.groupKey) {
        this.$root.$on(this.collapseGroupEvent, data => {
            this.show = data.show
        });
      }
    }
  }
</script>
