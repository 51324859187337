/*
Backported from https://github.com/rx/presenters/pull/203/files
*/

function convertLists(richtext) {
    const tempEl = window.document.createElement('div');
    tempEl.setAttribute('style', 'display: none;');
    tempEl.innerHTML = richtext;

    ['ul','ol'].forEach((type) => {
        const startTag = `::start${type}::::/start${type}::`;
        const endTag = `::end${type}::::/end${type}::`;

        // Grab each list, and work on it in turn
        Array.from(tempEl.querySelectorAll(type)).forEach((outerListEl) => {
            const listChildren = Array.from(outerListEl.children).filter((el) => el.tagName === 'LI');

            // Account for the fact that the first li might not be at level 0
            const firstLi = listChildren[0];
            firstLi.before(startTag.repeat(getListLevel(firstLi)));

            // Now work through each li in this list
            listChildren.forEach((listEl, index) => {
                const currentLiLevel = getListLevel(listEl);
                if (index < listChildren.length - 1) {
                    const difference = getListLevel(listChildren[index + 1]) - currentLiLevel;

                    // we only need to add tags if the level is changing
                    if (difference > 0) {
                        listChildren[index + 1].before(startTag.repeat(difference));
                    } else if (difference < 0) {
                        listEl.after(endTag.repeat(-difference));
                    }
                } else {
                    listEl.after(endTag);
                }
            });
            outerListEl.after(endTag);
        });
    });

    //  Get the content in the element and replace the temporary tags with new ones
    let newContent = tempEl.innerHTML;
    newContent = newContent.replace(/::startul::::\/startul::/g, '<ul>');
    newContent = newContent.replace(/::endul::::\/endul::/g, '</ul>');
    newContent = newContent.replace(/::startol::::\/startol::/g, '<ol>');
    newContent = newContent.replace(/::endol::::\/endol::/g, '</ol>');

    tempEl.remove();
    return newContent;
}

function getListLevel(el) {
    const className = el.className || '0';
    return +className.replace(/[^\d]/g, '');
}

export { convertLists };
