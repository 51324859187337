<template>
    <need-card :need="need" :read-only="readOnly"
        @editNeed="$emit('editNeed', need)"
        @groupNeeds="$emit('groupNeeds')"
        @copyNeed="(n, cb) => $emit('copyNeed', n, cb)">
        <move-menu
            slot="section-header-move-menu"
            v-if="canEdit && !readOnly"
            @sendToFirst="$emit('sendToFirst', need)"
            @sendToLast="$emit('sendToLast', need)">
        </move-menu>
        <move-menu
            slot="content-move-menu"
            v-if="canEdit && !sectionName && !readOnly"
            @sendToFirst="$emit('sendToFirst', need)"
            @sendToLast="$emit('sendToLast', need)"
        ></move-menu>
    </need-card>
</template>
<script>
import { ElementMixin } from 'vue-slicksort';

import MoveMenu from './MoveMenu.vue';
import NeedCard from './NeedCard.vue';

export default {
    name: 'sortable-need-card',
    props: ['need', 'readOnly'],
    components: { MoveMenu, NeedCard },
    mixins: [ ElementMixin ],
    computed: {
        canEdit() {
            return (this.$store.state.permissions.includes('plan.edit_need'));
        },
        sectionName() {
            return this.need.sectionName;
        }
    }
}
</script>
