<template>
    <div class="need-detail" :class="[sectionClass, archiveClass, priorityClass, selectedClass]" v-if="loaded">
        <section-header v-if="!need.isGroup" :section-name="need.sectionName"></section-header>

        <div class="row need-content-row">
            <div :class="needContentColClass">
                <need-content
                    :is-group="need.isGroup"
                    :needId="need.id"
                    :title="need.title"
                    :description="need.description"
                    :thumbnail-url="need.thumbnailUrl"
                    :image-url="need.imageUrl"
                    :detail-url="detailUrl"
                    :hide-lightbox="true"></need-content>
            </div>
            <div class="col-4 actions-col">
                <div v-if="copying" class="action-group">
                    <loading-indicator></loading-indicator>
                </div>
                <div v-if="canEdit && !copying && !readOnly" class="action-group">
                    <button v-bind:disabled="need.immutable" class="btn btn-outline-primary btn-block" @click.stop="onEdit">
                        Edit
                    </button>
                    <button v-bind:disabled="need.immutable || need.archived" class="btn btn-outline-primary btn-block" @click.stop="onCopy">
                        Copy
                    </button>
                    <button v-bind:disabled="need.priority || need.immutable" class="btn btn-outline-primary btn-block" @click.stop="onToggleArchive">
                        <i class="fas fa-fw fa-archive" :class="{'not-active': !need.archived}"></i>
                        <span v-if="need.archived">Unarchive</span>
                        <span v-else>Archive</span>
                    </button>
                    <button v-if="need.canTogglePriority" class="btn btn-outline-primary btn-block" @click.stop="onTogglePriority" :title="priorityTitle">
                        <i :class="priorityIconClass"></i>
                        Toggle Priority
                    </button>
                    <div class="priority-btn-tooltip-container" v-else v-b-tooltip="{placement: 'top', title: priorityTitle, container: '.zh-modal'}">
                        <button class="btn btn-outline-primary btn-block disabled">
                            <i :class="priorityIconClass"></i>
                            Toggle Priority
                        </button>
                    </div>

                    <div v-if="confirmDelete" class="mt-4">
                        <button class="btn btn-outline-dark btn-block" @click.stop="onCancelDelete">
                            <i class="fas fa-fw fa-ban"></i>
                            No, Cancel
                        </button>
                        <button class="btn btn-danger btn-block" @click.stop="onDelete">
                            <i class="fas fa-fw fa-trash"></i>
                            Yes, Delete
                        </button>
                    </div>
                    <button v-else v-bind:disabled="need.immutable" class="btn btn-outline-danger btn-block" @click.stop="onConfirmDelete">
                        <i class="fas fa-fw fa-trash"></i>
                        Delete
                    </button>
                </div>
            </div>
        </div>
        <div v-if="showAnswers">
            <div
                v-for="answer in answers"
                :key="answer.question.id"
                class="row"
            >
                <div class="col">
                    <hr />
                    <h4>{{ answer.question.label}}</h4>
                    <div v-if="answer.answer" v-html="answer.answer"></div>
                    <p v-else><em>This question has not been answered.</em></p>
                    <div class="ml-4" v-if="answer.requiresDocs">
                        <hr />
                        <h5><a class="nav-link" target="_blank" :href="answer.documentsUrl">Supporting Documents <i class="fas fa-external-link"></i></a></h5>
                        <!-- TODO: Empty state? -->
                        <ul class="list-group col-8" v-if="answer.documents.length > 0">
                            <a
                                v-for="(document, index) in answer.documents"
                                :key="`${answer.question.id}-doc-${index}`"
                                class="list-group-item list-group-item-action"
                                :href="document.download_url">
                                <i class="fas fa-fw fa-paperclip"></i>
                                {{ document.title }}
                            </a>
                        </ul>
                        <p class="ml-4" v-else>
                            <em>No supporting documents found.</em>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <hr v-if="showGroupedNeeds" />
        <div class="row" v-if="showGroupedNeeds">
            <div class="col grouped-needs-container">
                <h4>
                    Grouped Needs
                </h4>
                <grouped-need-card-list :show-all="true" v-if="showGroupedNeeds" @open="g => $emit('open', g)" :grouped-needs="groupedNeeds"></grouped-need-card-list>
            </div>
        </div>
    </div>
    <div v-else class="e">
        <p class="lead">Loading...</p>
    </div>
</template>
<script>
import SectionHeader from './SectionHeader.vue';
import NeedContent from './NeedContent.vue';
import GroupedNeedCardList from './GroupedNeedCardList.vue';
import { DELETE_NEED, FETCH_NEED, SET_NON_PRIORITY, SET_PRIORITY, SET_UNARCHIVED, SET_ARCHIVED, MESSAGE } from '../../constants';
import vBTooltip from 'bootstrap-vue/es/directives/tooltip/tooltip';

export default {
    name: 'need-detail',
    directives: {
        'b-tooltip': vBTooltip
    },
    created() {
        this.loadNeed();
        this.setTitle();
    },
    watch: {
        needId() {
            this.loadNeed();
            this.setTitle();
        }
    },
    props: ['needId', 'readOnly', 'detailUrl'],
    methods: {
        loadNeed() {
            if (this.need && !this.need.loaded) {
                const needId = this.$route.params.needId || this.needId;
                this.$store.dispatch(FETCH_NEED, {id: needId});
            }
        },
        setTitle() {
            document.title = `Need - ${this.need && this.need.title || 'Loading...'}`;
        },
        onEdit() {
            this.$emit('editNeed', this.need);
        },
        onCopy() {
            this.copying = true;
            this.$emit('copyNeed', this.need, newNeed => {
                this.$emit('editNeed', newNeed);
                this.copying = false;
                this.$store.dispatch(MESSAGE, { message: "Need successfully copied"});
            });
        },
        onTogglePriority() {
            const action = this.need.priority ? SET_NON_PRIORITY : SET_PRIORITY;
            this.$store.dispatch(action, { id: this.need.id });
        },
        onToggleArchive() {
            const action = this.need.archived ? SET_UNARCHIVED : SET_ARCHIVED;
            this.$store.dispatch(action, { id: this.need.id });
        },
        onConfirmDelete() {
            this.confirmDelete = true;
        },
        onCancelDelete() {
            this.confirmDelete = false;
        },
        onDelete() {
            this.$store.dispatch(DELETE_NEED, { id: this.need.id }).then(() => this.$emit('deleted'));
        }
    },
    data() {
        return {
            confirmDelete: false,
            copying: false
        }
    },
    computed: {
        need() {
            return this.$store.state.needs[this.needId];
        },
        plan() {
            return this.$store.getters.currentPlan;
        },
        answers() {
            const answers = [];
            const shown = this.need.priority && !this.need.group;
            if (!shown) {
                return answers;
            }
            this.plan.prioritizedNeedQuestions.forEach((question) => {
                const answerData = this.need.answers[`${question.id}`]
                answers.push({
                    question: question,
                    answer: answerData.answer,
                    requiresDocs: answerData.requires_supporting_documents,
                    documents: answerData.documents,
                    documentsUrl: answerData.supporting_documents_add_url,
                });
            });
            return answers
        },
        showAnswers() {
            // NOTE: This assumes TDOE's current logic that both a response _and_
            // an attachment must be provided.
            return this.answers.filter(answer => answer.answer).length > 0;
        },
        loaded() {
            return this.need && this.need.loaded;
        },
        canEdit() {
            return (this.$store.state.permissions.includes('plan.edit_need'));
        },
        showGroupedNeeds() {
            return this.need.isGroup && this.groupedNeeds.length > 0;
        },
        groupedNeeds() {
            return this.need.groupedNeeds.map(id => {
                if (this.$store.state.needs[id] === undefined) {
                    this.$store.dispatch(FETCH_NEED, { id });
                }
                return id;
            });
        },
        sectionClass() {
            return `section-${this.need.sectionSlug}`;
        },
        archiveClass() {
            return this.need.archived ? 'archived' : '';
        },
        priorityClass() {
            return this.need.priority ? 'priority' : '';
        },
        selectedClass() {
            return this.need.selected ? 'selected' : '';
        },
        priorityIconClass() {
            return this.need.priority ? 'fas fa-fw fa-star' : 'far fa-fw fa-star not-active';
        },
        needContentColClass() {
            return (!this.canEdit || this.readOnly) ? 'col-12' : 'col-8';
        },
        priorityTitle() {
            if (!this.need.canTogglePriority) {
                if (this.need.changesRequired) {
                    return 'Priority cannot be toggled because an approver has requested changes be made to this need'
                }
                else if (this.need.archived) {
                    return 'Priority cannot be toggled because this need has been archived';
                }
                else {
                    return `Priority cannot be toggled because plan status is "${this.need.planStatus}"`;
                }
            }
        }

    },
    components: {
        NeedContent,
        SectionHeader,
        GroupedNeedCardList
    }
}
</script>
