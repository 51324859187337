<template>
    <div v-if="show" class="audit-info">
        <span class="updated-by">
            <i class="fas fa-fw fa-user"></i>{{ updatedBy }}
        </span>
        <span class="dateline"><pretty-date :date="updatedAt"></pretty-date></span>
    </div>
</template>
<script>
import { SYSTEM_USER_EMAIL } from '../../constants';

export default {
    name: 'audit-info',
    props: ['updatedBy', 'updatedAt'],
    computed: {
        show() {
            return this.updatedBy != SYSTEM_USER_EMAIL;
        }
    }
}
</script>
