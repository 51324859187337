/* global window document */
import '../scss/site.scss';

Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.SHA,
    environment: process.env.SENTRY_ENVIRONMENT || 'local',
});

require('intersection-observer');
require('url-search-params-polyfill');

const $ = window.$;

require('bootstrap-datepicker');
require('ekko-lightbox');
require('eldarion-ajax');

import setupFundingSourcesFormset from './actionsteps';
import setupApprovalsTable from './approval_dashboards';
import setupTurnaroundPlanGoalFields from './goals';
import { setupEvidenceAttachmentsFormset, setupTurnaroundPlansFields } from './strategies';
import ajaxSendMethod from './ajax';
import loadEditor from './editor';
import setupEntityAutoComplete from './entities';
import {
    setupPlanSchoolYearToggle,
    setupPublicPlanSchoolYearSearch
} from './public';
import setupSourceAutoComplete from './ideasharing';
import setupApprovedByForm from './reviews';
import setupPlannerFeedbackStatusForm from './feedback';
import { addRemoveActionStep,
    installSelect2PostInitHandlers,
    preventUnloadOnEditedForms,
    setupNoScriptRedirect,
    setupToolTips,
    setupStickySideNav
} from './shortcuts';

import loadNeedsApp from './needs';
import setupPlanStatusFiltering from './plan_dashboards';
import setupActionStepAutoComplete from './linked_actionsteps';
import setupIdeaFiltering from './ideas';
import setupExportModals from './docs';

$(() => {
    setupNoScriptRedirect();

    $(document).on('click', '[data-toggle="lightbox"]', function (event) {
        event.preventDefault();
        $(this).ekkoLightbox({
            alwaysShowClose: true
        });
    });

    preventUnloadOnEditedForms();

    setupEntityAutoComplete();
    setupSourceAutoComplete();
    setupActionStepAutoComplete();
    setupIdeaFiltering();
    loadNeedsApp();

    $(document).ajaxSend(ajaxSendMethod);

    /* eslint-disable no-console */
    $('body').on('change', 'input[type="file"][data-toggle="custom-file"]', function (event) { // eslint-disable-line no-unused-vars
        const $input = $(this);
        const target = $input.data('target');
        const $target = $(target);

        if (!$target.length)
            return console.error('Invalid target for custom file', $input);

        if (!$target.attr('data-content'))
            return console.error('Invalid `data-content` for custom file target', $input);

        // set original content so we can revert if user deselects file
        if (!$target.attr('data-original-content')) {
            $target.attr('data-original-content', $target.attr('data-content'));
        }

        const input = $input.get(0);

        let name = input
        && input.files
        && input.files[0]
        && input.files[0].name ? input.files[0].name : $input.val();

        if (!name) {
            name = $target.attr('data-original-content');
        }

        $target.attr('data-content', name);
    });
    /* eslint-enable no-console */
    loadEditor();

    $('body.goal-form #id_sections').select2();

    setupTurnaroundPlanGoalFields();
    setupTurnaroundPlansFields();
    // setupNeedFiltering();
    setupPlanStatusFiltering();
    setupApprovedByForm();
    setupPlannerFeedbackStatusForm();

    // when the select2 is changed, send to changes via ajax to be updated and the fragment re-rendered.
    var $el = $('.planning-questions');
    $el.on('select2:unselect select2:select', function (event, data) { // eslint-disable-line no-unused-vars
        addRemoveActionStep(event);
    });

    $(document).on('eldarion-ajax:success', (event, $el, responseData, textStatus, jqXHR) => { // eslint-disable-line no-unused-vars
        if (responseData.location) {
            // NOTE: Safari and Chrome will not refetch the page if responseData.location
            // has the same origin and pathname.  Changing the hash or querystring doesn't make a difference
            // either.
            // The approach below compares `pathname`; if the value is the same,
            // we will override eldarion-ajax's built in response handler with our own.
            const redirectUrl = responseData.location.startsWith('/') ? new URL(responseData.location, window.location.origin) : new URL(responseData.location);
            if (redirectUrl.pathname == window.location.pathname) {
                // NOTE: THis overrides the response handler by
                // setting window.location and then immediately issuing a
                // reload.
                window.location.href = responseData.location;
                window.location.reload();
                return false;
            }
        }
    });

    $(document).on('eldarion-ajax:complete', (event, $el, responseData, textStatus, jqXHR) => { // eslint-disable-line no-unused-vars
        preventUnloadOnEditedForms(true);
        loadEditor();

        if ($el.hasClass('actionstep-modal-trigger') || $el.hasClass('form-action-step')) {
            $($el.data('modal-id')).modal('show');
            $('#id_estimated_completion_date').datepicker();
            installSelect2PostInitHandlers();
            setupFundingSourcesFormset();
        }
        if ($el.hasClass('btn-answer-form')) {
            setupActionStepAutoComplete($($el.data('replace')).find('.linked-elements-select,.answers-select'));
        }
        if ($el.hasClass('form-action-step')) {
            setupActionStepAutoComplete($(responseData.answers_fragment).find('.answers-select'));
        }
        if ($el.parent().parent().attr('id') === 'prioritized-needs') {
            loadNeedsApp();
        }
    });

    installSelect2PostInitHandlers();
    setupStickySideNav();
    setupToolTips();
    setupExportModals();
    setupPublicPlanSchoolYearSearch();
    setupPlanSchoolYearToggle();
    setupEvidenceAttachmentsFormset();
    setupApprovalsTable();
});
