<template>
    <div class="move-menu">
        <span title="Move to the top of the list" class="move-first far fa-fw fa-arrow-to-top" @click.stop="$emit('sendToFirst')"></span>
        <span title="Move to the bottom of the list" class="move-last far fa-fw fa-arrow-to-bottom" @click.stop="$emit('sendToLast')"></span>
        <span title="Drag to re-order this need" v-handle class="handle far fa-fw fa-arrows"></span>
    </div>
</template>
<script>
import { HandleDirective } from 'vue-slicksort';

export default {
    name: 'move-menu',
    directives: { handle: HandleDirective }
}
</script>
