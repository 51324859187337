<template>
    <transition name="modal">
        <div class="zh-overlay" @click.stop="onOverlayClick">
            <div class="zh-modal" @click.stop="onModalClick">
                <div class="zh-title">
                    <slot name="title"></slot>
                    <span class="exit" @click.stop="$emit('close')"><i class="text-muted fa fas fa-times"></i></span>
                </div>
                <system-messages></system-messages>
                <div class="zh-content">
                    <div class="zh-content-container">
                        <slot name="body"></slot>
                    </div>
                </div>
                <div class="zh-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
const BODY_MODAL_CLASS = 'showing-modal';
export default {
    name: 'modal',
    props: ['backdrop'],
    created() {
        const el = document.body;
        if (el.classList) {
            el.classList.add(BODY_MODAL_CLASS);
        }
        else {
            el.className += ' ' + BODY_MODAL_CLASS;
        }
    },
    destroyed() {
        const el = document.body;
        if (el.classList) {
            el.classList.remove(BODY_MODAL_CLASS);
        }
        else {
            el.className = el.className.replace(new RegExp('(^|\\b)' + BODY_MODAL_CLASS.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    },
    methods: {
        onOverlayClick() {
            if (this.backdrop != 'static') {
                this.$emit('close');
            }
        },
        onModalClick() {
            console.log('modal click');
        }
    }
}
</script>
