const uniqueValues = function (rowData, columnIndex) {
  // @@@ apply non-alphanumeric once we have constants defined
  const theValues = Array();
  rowData.each(function (data, _pos) {
    const theVal = data[columnIndex]['@data-filter'];
    if (!theValues.includes(theVal)) {
      theValues.push(theVal);
    }
  });
  return theValues;
};

const setupColumnHeaders = function (column, dataIdx) {
  const oneBasedIdx = dataIdx + 1;
  const shadowHeader = $(column.header()).parent().siblings().first().find(`th:nth-child(${oneBasedIdx})`).first();

  if (column.index() > 0) {
    const select = $('<select class="form-control"><option value="">All</option></select>')
      .appendTo($(shadowHeader).empty())
      .on('change', function () {
        const val = $(this).val();
        // NOTE: We disable the case-sensitivty setting
        // to avoid matching on "In Review" vs "Pending Review"
        // see https://datatables.net/reference/api/column().search()
        column.search(val, false, true, false).draw();
      });
    const values = uniqueValues(column.table().rows().data(), column.index());
    $.each(values, function (pos, value) {
      select.append(`<option value="${value}">${value}</option>`);
    });
    const existingValue = column.search();
    if (existingValue) {
      const $option = select.find(`option[value="${existingValue}"]`);
      // @@@ a bit of a kludge in that the "stored" value is no longer
      // included in the rows; consider refactoring with constants
      if ($option.length != 1) {
        select.append(`<option value="${existingValue}">${existingValue}</option>`);
      }
      select.find(`option[value="${existingValue}"]`).prop('selected', true);
    }
  }
  else {
    // we explicitly decide to discard previous filter values,
    // to mimic the typeahead on the main dashboard
    column.search('').draw();
    const input = $('<input class="form-control" type="text" placeholder="Search organizations..." />');
    input.appendTo($(shadowHeader).empty());
    input.on('keyup change', function (evt) {
      if (column.search() !== this.value) {
        column.search(this.value).draw();
      }
    });
  }
};

const initializeApprovalsTable = function () {
  $('#approvalsTable thead tr').clone(true).appendTo('#approvalsTable thead');

  const approvalDataTable = $('#approvalsTable').DataTable({
    // @@@ refactor using the scroller plugin
    dom: 'tipl',
    columnDefs: [
      {
        targets: 0,
        className: 'noVis'
      },
      {
        targets: 1,
        className: 'noVis'
      }
    ],
    buttons: [
      {
        extend: 'colvis',
        columns: ':not(.noVis)',
        text: 'Show / Hide Teams',
        className: 'flex-fill ml-4'
      }
    ],
    lengthMenu: [[10, 25, 50, -1], [10, 25, 50, 'All']],
    orderCellsTop: true,
    pageLength: 10,
    scrollX: true,
    stateSave: true,
    language: {
      zeroRecords: 'No plans found.'
    },
    initComplete: function () {
      const myApi = $('#approvalsTable').dataTable().api();
      this.api().columns().every(function () {
        const column = this;
        const dataIdx = myApi.column.index('fromData', column.index());
        if (column.visible()) {
          setupColumnHeaders(column, dataIdx);
        }
      });
    }
  });

  approvalDataTable.buttons().container().appendTo('.box-section-header');

  // Set up reset link hanlder
  $('#dt-reset').on('click', function () {
    const dt = $('#approvalsTable').dataTable().api();
    dt.state.clear();
    // @@@ what about a redraw instead of an entire DOM rewrite?
    // @@@ we would also likely need to reset the CORE region
    const url = window.location.href.split('?')[0];
    window.location = `${url}?show_all=true`;
  });

  $('#approvalsTable').on('column-visibility.dt', function (e, settings, column, state) {
    const myApi = $('#approvalsTable').dataTable().api();
    const thisColumnObj = myApi.column(column);
    // discard the previous value of the column when we toggle its visibility
    thisColumnObj.search('').draw();
    if (state) {
      // if column is shown, we need to add its header
      const dataIdx = myApi.column.index('fromData', thisColumnObj.index());
      setupColumnHeaders(thisColumnObj, dataIdx);
    }
  });
};

const setupApprovalsTable = function setupApprovalsTable () {
  if ($('#approvalsTable').length > 0) {
    initializeApprovalsTable();
  }
};

export default setupApprovalsTable;
