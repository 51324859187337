<template>
    <div class="filter-toggle-menu">
        <label @click="onToggleShow"><slot></slot></label>
        <div class="filter-toggles" v-if="active">
            <filter-toggle-menu-item v-for="(item, index) in items" :key="index" :item="item" :active="value.indexOf(item.value) > -1" @selected="onItemSelect">
                {{ item.label }}
            </filter-toggle-menu-item>
        </div>
    </div>
</template>
<script>
import FilterToggleMenuItem from './FilterToggleMenuItem.vue';
export default {
    name: 'filter-toggle-menu',
    components: {
        FilterToggleMenuItem
    },
    props: [
        'active',
        'items',
        'value',
        'multiple'
    ],
    methods: {
        onToggleShow() {
            this.$emit('toggleMenu');
        },
        onItemSelect(item) {
            let value = [
                ...this.value
            ];
            if (this.multiple) {
                const index = value.indexOf(item.value);
                if (index === -1) {
                    value.push(item.value);
                }
                else {
                    value.splice(index, 1);
                }
            } else {
                value = [item.value];
            }
            this.$emit('input', value);
            this.$emit('toggleMenu');
        }
    }
}
</script>

