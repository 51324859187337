<template>
    <div class="copy-needs-form">
        <button v-if="selectedNeeds.length === 0" class="btn btn-sm btn-info" @click.prevent="selectAll">
            <i class="fas fa-fw fa-copy"></i>
            Select All
        </button>
        <span class="loading" v-else-if="copying">
            <loading-indicator></loading-indicator>
            <div class="ml-2 mr-4">Copying {{ selectedNeeds.length }} {{ needLabel }}</div>
        </span>
        <span v-else>
            <select class="custom-select" v-model="planId">
                <option v-for="plan in plans" :key="plan.id" :value="plan.id">{{ plan.label }}</option>
            </select>
            <button class="btn btn-sm btn-info" :disabled="planId === null" @click.prevent="copy">
                <i class="fas fa-fw fa-copy"></i>
                Copy {{ selectedNeeds.length }} {{ needLabel }}
            </button>
            <button class="btn btn-sm btn-info" :disabled="planId === null" @click.prevent="copyArchive">
                <i class="fas fa-fw fa-copy"></i>
                Copy &amp; Archive {{ selectedNeeds.length }} {{ needLabel }}
            </button>
            <button class="btn btn-sm btn-light" @click.prevent="cancel">
                <i class="fas fa-fw fa-ban"></i>
                Cancel
            </button>
        </span>
    </div>
</template>
<script>
import { RESET_SELECTED_NEEDS, SELECT_ALL_NEEDS, COPY_NEEDS } from '../../constants';

export default {
    name: 'copy-needs-form',
    data() {
        return {
            planId: null,
            copying: false
        }
    },
    methods: {
        cancel() {
            this.$store.dispatch(RESET_SELECTED_NEEDS);
        },
        copy() {
            if (this.planId) {
                this.copying = true;
                this.$store.dispatch(COPY_NEEDS, { ids: this.selectedNeeds, planId: this.planId })
                    .then(() => this.copying = false);
            }
        },
        copyArchive() {
            if (this.planId) {
                this.copying = true;
                this.$store.dispatch(COPY_NEEDS, { ids: this.selectedNeeds, planId: this.planId, archive: true })
                    .then(() => this.copying = false);
            }
        },
        selectAll() {
            this.$store.dispatch(SELECT_ALL_NEEDS, { needs: this.filteredNeeds });
        }
    },
    computed: {
        filteredNeeds() {
            return this.$store.getters.filteredNeeds;
        },
        selectedNeeds() {
            return this.$store.state.selectedNeeds;
        },
        needLabel() {
            return this.selectedNeeds.length === 1 ? 'Need' : 'Needs';
        },
        plans() {
            return this.$store.state.plansOrder.map(id => this.$store.state.plans[id]);
        }
    }
}
</script>
