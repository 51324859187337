export default {
    filtered: state => state.filters.archived !== false || state.filters.priority !== null || state.filters.sections.length > 0 || state.search !== '',
    filteredNeeds: (state, getters) => {
        let needs = [...getters.groupedNeeds];
        if (state.filters.archived !== null) {
            needs = needs.filter(n => n.archived == state.filters.archived);
        }
        if (state.filters.priority !== null) {
            needs = needs.filter(n => n.priority == state.filters.priority);
        }
        if (state.filters.sections.length > 0) {
            needs = needs.filter(n => {
                if (state.filters.sections.indexOf(n.sectionName) > -1) {
                    return true;
                }
                const groupedNeeds = n.groupedNeeds.map(id => state.needs[id]);
                if (groupedNeeds.filter(gn => state.filters.sections.indexOf(gn.sectionName) > -1).length > 0) {
                    return true;
                }
            });
        }
        if (state.search !== '') {
            needs = needs.filter(n => {
                const term = state.search.toLowerCase();
                const groupedNeeds = n.groupedNeeds.map(id => state.needs[id]);
                const searchValues = [n.title, n.description];
                groupedNeeds.forEach(gn => {
                    searchValues.push(gn.title);
                    searchValues.push(gn.description);
                });
                return searchValues.filter(v => v.toLowerCase().indexOf(term) > -1).length > 0;
            });
        }

        return needs;
    },
    needs: state => state.needsOrder.map(id => state.needs[id]),
    groupedNeeds: (state, getters) => getters.needs.filter(n => n.group === null),
    currentPlan: ((state) => {
        // NOTE: `ready` check ensures we don't hit a race condition
        // between setting planId and populating plans.
        const ready = state.planId && state.plans[state.planId];
        return ready ? state.plans[state.planId] : null;
    }),
};
