const GROUPED_NEEDS_LIMIT = 3;

export const GroupedNeedsMixin = {
    props: {
        groupedNeeds: {
            type: Array,
            required: true,
        },
        showAll: {
            type: Boolean,
            required: false,
        }
    },
    computed: {
        sortedNeeds () {
            const indicies = this.groupedNeeds.map(id => this.$store.state.needsOrder.indexOf(id));
            indicies.sort();
            const needs = indicies.map(i => {
                return this.$store.state.needs[this.$store.state.needsOrder[i]];
            });
            return needs;
        },
        needs () {
            return this.showAll ? this.sortedNeeds : this.sortedNeeds.slice(0, GROUPED_NEEDS_LIMIT);
        },
        leftOver () {
            return this.sortedNeeds.slice(GROUPED_NEEDS_LIMIT);
        }
    }
};


export const EditorMixin = {
    methods: {
        cleanValue (value) {
            /*
             * when removing text from an editable the editor inserts `<p><br></p>` instead
             * of a null value.  Clean this out so we don't save it to the db.
             */
            let content = value;
            const emptyFragments = ['<p><br></p>', '<p class=""><br></p>'];
            if (emptyFragments.indexOf(content) > -1) {
                content = '';
            }
            return content;
        }
    }
};
