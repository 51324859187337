<template>
    <div class="box-footer">
        <span :class="priorityClass">
            <i :class="priorityIconClass"></i>
        </span>
    </div>
</template>
<script>
export default {
    name: 'priority-bar',
    props: ['priority'],
    computed: {
        priorityClass() {
            return this.priority ? 'priority priority-ro' : 'priority-ro';
        },
        priorityIconClass() {
            return this.priority ? 'fas fa-fw fa-star' : 'far fa-fw fa-star not-active';
        },
    }
}
</script>
