const setupPlanStatusFiltering = () => {
    const $body = $('.dashboard, .planning-status');
    $body.find('#statuses-form select').change(function () {
        $('#statuses-form').submit();
    });
    $body.find('#statuses-form #id_plan_kind input').change(function () {
        $('#statuses-form').submit();
    });
    $body.find('.toggle-order').on('click', function (el) {
        el.preventDefault();
        $('#id_order_by').val($(this).attr('data-order-value'));
        $('#statuses-form').submit();
    });
};

export default setupPlanStatusFiltering;
