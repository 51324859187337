import axios from 'axios';
import store from './store';
import { ERROR } from './constants';

const handleErrorResponse = error => {
    try {
        if (error.response === undefined ) {
            // @@@ capture further debugging information
            console.log('Detected a network error');
            return Promise.reject(error);
        }
        else if (error.response.status === 500) {
            // received 500 response; emit unhandledrejection event
            return Promise.reject(error);
        }
        else if (error.response.status === 403 && error.response.data.location) {
            // follow session security middleware redirect
            window.location = error.response.data.location;
        }
        else {
            // show in SystemMessages component
            store.dispatch(ERROR, { error: error.response, default_message: error.message });
        }
    }
    catch (newError) {
        // unhandled exception processing the error response; emit unhandledrejection event
        return Promise.reject(error);
    }
};

class ErrorAwareService {
    /*
    Provide an HTTP Service that handles errors intelligently.
    */
    constructor () {
        this.service = axios.create({
            baseURL: '/api/v1/',
            xsrfHeaderName: 'X-CSRFToken',
            xsrfCookieName: 'csrftoken',
            headers: { 'X-Vue-Frontend': 'Made by Eldarion' }
        });
    }
    async get (path, callback) {
        try {
            const response = await this.service.get(path);
            return callback(response.data);
        }
        catch (error) {
            return handleErrorResponse(error);
        }
    }
    async post (path, callback, data, config) {
        try {
            const response = await this.service.post(path, data, config);
            return callback(response.data);
        }
        catch (error) {
            return handleErrorResponse(error);
        }
    }
}

const HTTP = new ErrorAwareService;

export default HTTP;
