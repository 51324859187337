<template>
    <div v-if="open" class="lightbox">
        <div class="lightbox-container">
            <lazy-image classes="img-fluid" :src="src" :alt="title" @click="onClose"></lazy-image>
        </div>
    </div>
    <lazy-image v-else classes="img-fluid" :src="thumbnail" :alt="title" @click="onOpen"></lazy-image>
</template>
<script>
export default {
    name: 'light-box',
    props: {
        images: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            open: false,
            index: 0
        }
    },
    methods: {
        onOpen() {
            this.open = true;
        },
        onClose() {
            this.open = false;
        }
    },
    computed: {
        title() {
            return this.images[0].caption;
        },
        thumbnail() {
            return this.images[0].thumb;
        },
        src() {
            return this.images[this.index].src;
        }
    }
}
</script>
