import typeahead from 'corejs-typeahead'; // eslint-disable-line no-unused-vars
import Bloodhound from 'corejs-typeahead/dist/bloodhound';

import { prefetchDefaults, showAllResultsForEngine, sorterDefaults } from './typeahead-common';

const setupActionStepAutoComplete = $el => {
    if ($el) {
        if ($el.hasClass('linked-elements-select')) {
            setupLinkedActionSteps($el);
        }
        else if ($el.hasClass('answers-select')) {
            setupAnswer($el);
        }
    }
    else {
        const $widgets = $('.linked-elements-select');
        $widgets.each((i, el) => {
            setupLinkedActionSteps($(el));
        });
        const $questionWidgets = $('.answers-select');
        $questionWidgets.each((i, el) => {
            setupAnswer($(el));
        });
    }
};

const setupLinkedActionSteps = $el => {
    const engine = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('ordinal_label', 'title'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        identify: obj => obj.pk,
        initialize: false,
        prefetch: prefetchDefaults($el.data('linked-element-choices-url')),
        sorter: sorterDefaults
    });
    engine.installTypeahead = ($el) => {
        // wait until the typeahead element has been clicked
        // to trigger loading of data
        const $input_el = $el.find('.typeahead');
        if (!$input_el.hasClass('initialized')) {
            // store the original placeholder content
            const original_placeholder = $input_el.attr('placeholder');

            $el.busyLoad('show', {spinner: 'accordion', text: 'Loading choices...', textPosition: 'right', fontSize: '1.5rem'});

            // make the AJAX request to load data
            var promise = engine.initialize();
            promise.done(() => {
                $el.busyLoad('hide');

                $input_el.typeahead({
                    hint: true,
                    highlight: true,
                    minLength: 0
                },
                {
                    name: 'linked-actionsteps',
                    source: showAllResultsForEngine(engine),
                    display: 'title',
                    limit: 1000,
                    templates: {
                        suggestion: obj => {
                            return `<div><div class="tt-linked-actionstep">${obj.ordinal_label} ${obj.title}</div></div>`;
                        }
                    }
                }).bind('typeahead:select', (ev, suggestion) => {
                    $el.busyLoad('show', {spinner: 'accordion', text: 'Saving...', textPosition: 'right', fontSize: '1.5rem'});

                    const url = $el.data('linked-element-connect-url');
                    const id = $el.data('replace');
                    const $replace = $(id);
                    $.ajax({
                        method: 'post',
                        url: url,
                        data: suggestion,
                        dataType: 'json'
                    }).done(responseData => {
                        engine.clear();
                        $replace.replaceWith(responseData.html);
                        setupLinkedActionSteps($(id).find('.linked-elements-select'));

                        $el.busyLoad('hide');
                    });
                // focus the user's input onto the typeahead
                }).attr('placeholder', original_placeholder
                ).focus();
            });
            $input_el.addClass('initialized');
        }
    };
    $el.find('.typeahead').on('click', (evt) => {
        const $input_el = $(evt.target);
        const $parent_el = $input_el.parents('.linked-elements-select');
        engine.installTypeahead($parent_el);
    });
};

const setupAnswer = $el => {
    const engine = new Bloodhound({
        datumTokenizer: ((datum) => {
            const ngrams = Bloodhound.tokenizers.obj.ngram([
                'label',
                'question',
                'external_id',
            ])(datum);
            const externalIdPermutations = [];
            // NOTE: Allows searches for 010 or 10 to return
            // D-010
            if (datum.external_id) {
                const numberish = datum.external_id.split('-')[1];
                externalIdPermutations.push(numberish);
                externalIdPermutations.push(`${parseInt(numberish)}`);
            }
            return ngrams.concat(externalIdPermutations);
        }),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        identify: obj => obj.pk,
        prefetch: prefetchDefaults($el.data('answer-choices-url')),
        sorter: sorterDefaults,
    });
    $el.find('.typeahead').typeahead({
        hint: true,
        highlight: true,
        minLength: 0
    },
    {
        name: 'questions',
        source: showAllResultsForEngine(engine),
        limit: 1000,
        display: 'question',
        templates: {
            suggestion: ((obj) => {
                const questionLabel = obj.external_id ? `<div class="tt-question-label">${obj.label}</div><div class="tt-question-external-id">${obj.external_id}</div>` : `<div class="tt-question-label">${obj.label}</div>`;
                return `<div class="tt-question"><div class="tt-question-header">${questionLabel}<div class="tt-question-category">${obj.category}</div></div><div class="tt-question-title">${obj.question}</div></div>`;
            })
        }
    }).bind('typeahead:select', (ev, suggestion) => {
        // TODO: Prevent flicker on selection
        const url = $el.data('answer-connect-url');
        const id = $el.data('replace');
        const $replace = $(id);
        $.ajax({
            method: 'post',
            url: url,
            data: suggestion,
            dataType: 'json'
        }).done(responseData => {
            engine.clear();
            $replace.replaceWith(responseData.html);
            setupAnswer($(id).find('.answers-select'));
        });
    });
};

export default setupActionStepAutoComplete;
