<template>
    <div class="needs-app-container">
        <div class="loading" v-if="!loaded">Loading...</div>
        <router-view v-else></router-view>
    </div>
</template>
<script>
import {
    FETCH_NEEDS,
    FETCH_NEED,
    FETCH_PLAN,
    SET_PLAN_ID,
    FETCH_SECTIONS,
    FETCH_PERMISSIONS,
    FETCH_PLANS
} from './constants';

export default {
    name: 'needs-app',
    computed: {
        loaded() {
            return (this.$store.state.loaded && this.plan) ? true : false;
        },
        plan() {
            return this.$store.getters.currentPlan;
        },
    },
    created() {
        const planId = this.$route.params.planId;
        const needId = this.$route.params.needId;
        this.$store.dispatch(FETCH_PERMISSIONS);

        if (!this.loaded && planId) {
            this.$store.dispatch(FETCH_SECTIONS);
            this.$store.dispatch(SET_PLAN_ID, {planId});
            this.$store.dispatch(FETCH_PLAN, {id: planId});
            this.$store.dispatch(FETCH_PLANS, {id: planId});
            this.$store.dispatch(FETCH_NEEDS, {id: planId});
        }
        else if (!this.loaded && needId) {
            this.$store.dispatch(FETCH_NEED, {id: needId}).then(() => {
                const need = this.$store.state.needs[needId];
                this.$store.dispatch(FETCH_SECTIONS);
                this.$store.dispatch(SET_PLAN_ID, {planId: need.plan});
                this.$store.dispatch(FETCH_PLAN, {id: need.plan});
                this.$store.dispatch(FETCH_PLANS, {id: need.plan});
                this.$store.dispatch(FETCH_NEEDS, {id: need.plan});
            });
        }
    }
}
</script>
