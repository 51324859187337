import { NeedsDashboard } from '../components';

export default [
    {
        path: '/needs/:needId/', component: NeedsDashboard, name: 'need-detail', pathToRegexpOptions: {
            strict: true
        }
    },
    {
        path: '/plans/:planId/needs/analyze/', component: NeedsDashboard, name: 'needs-dashboard', pathToRegexpOptions: {
            strict: true
        }
    }
];
