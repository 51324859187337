<template>
    <div :class="messageClass" class="system-message alert" role="alert">
        <button @click.stop="$emit('clearMessage')" class="close">&times;</button>
        <div v-html="message.message"></div>
    </div>
</template>
<script>
export default {
    name: 'success-message',
    props: ['message'],
    computed: {
        messageClass() {
            const alertClass = this.message.alertClass || 'success';
            return `alert-${alertClass}`;
        }
    }
}
</script>
