<template>
    <div class="system-messages sticky-top" :class="systemMessagesClass">
        <error-message
            v-for="(error, index) in errors"
            :key="index"
            :error="error"
            @clearMessage="onClearError(index)">
        </error-message>
        <success-message
            v-for="(message, index) in messages"
            :key="index"
            :message="message"
            @clearMessage="onClearMessage(index)">
        </success-message>
    </div>
</template>
<script>
import { CLEAR_ERROR, CLEAR_MESSAGE } from '../../constants';
import ErrorMessage from './ErrorMessage.vue';
import SuccessMessage from './SuccessMessage.vue';

export default {
    name: 'system-messages',
    components: {
        ErrorMessage,
        SuccessMessage
    },
    computed: {
        errors() {
            return this.$store.state.errors;
        },
        messages() {
            return this.$store.state.messages;
        },
        systemMessagesClass() {
            const hasErrors = this.errors.length > 0;
            const hasMessages = this.messages.length > 0;
            return ( hasErrors || hasMessages ) ? '' : 'd-none';
        }
    },
    methods: {
        onClearError(index) {
            this.$store.dispatch(CLEAR_ERROR, index);
        },
        onClearMessage(index) {
            this.$store.dispatch(CLEAR_MESSAGE, index);
        }
    }
}
</script>
