<template>
    <need-card :need="need" :reference-only="referenceOnly" :read-only="true" @open="$emit('open')">
        <ungroup-need
            slot="ungroup-need"
            :need="need"
            :read-only="readOnly">
        </ungroup-need>
    </need-card>
</template>
<script>

import NeedCard from './NeedCard.vue';
import UngroupNeed from './UngroupNeed.vue';

export default {
    name: 'grouped-need-card',
    props: ['need', 'readOnly', 'referenceOnly'],
    components: {
        NeedCard,
        UngroupNeed
    },
    computed: {
        canEdit() {
            return (this.$store.state.permissions.includes('plan.edit_need'));
        },
        sectionName() {
            return this.need.sectionName;
        }
    }
}
</script>
