import typeahead from 'corejs-typeahead'; // eslint-disable-line no-unused-vars
import Bloodhound from 'corejs-typeahead/dist/bloodhound';

import { prefetchDefaults, showAllResultsForEngine, sorterDefaults } from './typeahead-common';

const setupEntityAutoComplete = function setupEntityAutoComplete () {
    const $entitySelects = $('.entity-select');
    $entitySelects.each((i, el) => {
        const $el = $(el);
        const engine = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.nonword('name'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            identify:  obj => obj.id,
            initialize: false,
            prefetch: prefetchDefaults($el.data('entity-choices-url')),
            sorter: sorterDefaults,
        });
        engine.installTypeahead = ($input_el) => {
            // wait until the typeahead element has been clicked
            // to trigger loading of data
            if (!$input_el.hasClass('initialized')) {
                // store the original placeholder content
                const original_placeholder = $input_el.attr('placeholder');
                $input_el.attr('placeholder', 'Loading...');

                // make the AJAX request to load data
                var promise = engine.initialize();
                promise.done(() => {
                    // set up the typeahead
                    $input_el.typeahead({
                        hint: true,
                        highlight: true,
                        minLength: 0
                    },
                    {
                        name: 'entities',
                        source: showAllResultsForEngine(engine),
                        display: 'name',
                        limit: engine.all().length,
                        templates: {
                            suggestion: obj => {
                                return `<div><div class="tt-entity">${obj.name}</div></div>`;
                            }
                        }
                    }).bind('typeahead:select', function (ev, suggestion) {
                        window.location.href = `${suggestion.url}`;
                    // restore the original placeholder
                    }).attr('placeholder', original_placeholder
                    // focus the user's input onto the typeahead
                    ).focus();
                });
                $input_el.addClass('initialized');
            }
        };
        $el.siblings().closest('.typeahead').on('click', (evt) => {
            const $input_el = $(evt.target);
            engine.installTypeahead($input_el);
        });
    });
};

export default setupEntityAutoComplete;
