import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.min.css';

const setupFundingSourcesFormset = function setupFundingSourcesFormset () {
    if ($('.funding-sources-formset').length > 0 ) {
        $('.funding-sources-formset .formset-row').formset({
            prefix: $('.funding-sources-formset').data('prefix'),
            addText: '<i class="fas fa-fw fa-plus"></i> Add funding source',
            addCssClass: 'add-row d-block',
            deleteText: '<i class="fas fa-trash"></i>',
            deleteCssClass: 'delete-row order-2 col-1 text-danger'
        });
    }
};

export default setupFundingSourcesFormset;
