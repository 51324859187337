<template>
    <div class="rich-editor">
        <vue-editor
            :id="editorId"
            useCustomImageHandler
            @imageAdded="handleImageAdded"
            :value="value"
            :editorToolbar="customToolbar"
            @input="handleInput"
            output="html">
        </vue-editor>
    </div>
</template>
<script>
import { toolbarWithImages } from '../../../editor';
import { VueEditor } from 'vue2-editor';
import api from '../../api';

export default {
    name: 'rich-editor',
    props: ['editorId', 'value', 'imageSet'],
    computed: {
        customToolbar() {
            return toolbarWithImages;
        }
    },
    methods: {
        handleInput(editorContent) {
            this.$emit('input', editorContent);
        },
        handleImageAdded(file, Editor, cursorLocation, resetUploader) {
            const id = this.imageSet ? this.imageSet.pk : null;
            api.uploadImage(id, file, (data) => {
                const url = data.images[0].thumbnail;
                if (id === null) {
                    this.$emit('imageSetCreated', data);
                }
                Editor.insertEmbed(cursorLocation, 'image', url);
                resetUploader();
            });
        }
    },
    components: {
        VueEditor
    }
}
</script>
