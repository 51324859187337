<template>
    <div class="need-content">
        <h5 class="need-title">
            <span v-if="referenceOnly">
                <a v-if="hasDescription" data-toggle="collapse" v-b-toggle="'need' + needId"><i class="collapse-toggler"></i></a>
                {{ title }}
            </span>
            <template v-else>
                <span>
                    <i v-if="isGroup" class="fas fa-fw fa-th-large text-info"></i>
                    <title-link :need-id="needId" :title="title" @open="$emit('open')"></title-link>
                </span>
                <a class="btn btn-xs btn-link" v-if="detailUrl" :href="detailUrl" target="_blank">
                   Make Changes <i class="fas fa-fw fa-external-link"></i>
                </a>
            </template>
            <slot></slot>
        </h5>
        <a v-if="hideLightbox && thumbnailUrl" :href="imageUrl" target="_blank">
            <lazy-image classes="img-fluid" :src="thumbnailUrl" :alt="title"></lazy-image>
        </a>
        <light-box v-else-if="thumbnailUrl" :images="[{ thumb: thumbnailUrl, src: imageUrl, caption: title }]"></light-box>
        <div v-if="!referenceOnly" class="need-description" :class="{overflowed: isYOverflowed}" v-html="description" ref="needDescription"></div>
        <div v-if="referenceOnly && hasDescription > 0" class="need-description-wrapper">
            <b-collapse :group-key="groupId" v-bind:ref="'needCollapse' + needId" class="need-description" v-bind:id="'need' + needId" v-html="description"></b-collapse>
        </div>

    </div>
</template>
<script>
import vBToggle from 'bootstrap-vue/es/directives/toggle/toggle';

import GroupedCollapse from '../bootstrap-vue-extras/GroupedCollapse.vue';
import TitleLink from './TitleLink.vue';

export default {
    name: 'need-content',
    directives: {
        'b-toggle': vBToggle
    },
    props: ['groupId', 'isGroup', 'needId', 'title', 'imageUrl', 'thumbnailUrl', 'description', 'hideLightbox', 'readOnly', 'referenceOnly', 'detailUrl'],
    components: {
        TitleLink,
        'b-collapse': GroupedCollapse,
    },
    data() {
        return {
            isYOverflowed: false
        }
    },
    mounted() {
        this.reCalcOverflow();
    },
    methods: {
        reCalcOverflow() {
            this.$nextTick(() => {
                const el = this.$refs.needDescription;
                if (el) {
                    this.isYOverflowed = el.scrollHeight > el.clientHeight;
                }
            });
        }
    },
    watch: {
        $route() {
            this.reCalcOverflow();
        }
    },
    computed: {
        clippedTitle() {
            return this.title.slice(0, 120);
        },
        hasDescription() {
            const descriptionText = new DOMParser().parseFromString(this.description, "text/html").body.textContent;
            return descriptionText.length > 0;
        },
    }
}
</script>
