import stickybits from 'stickybits';

const addRemoveActionStep = function addRemoveActionStep (event) {
    const actionlist = $(event.target).parent().parent().children('.selected-actionsteps');

    var url = '';
    if (event.type === 'select2:select') {
        url = $(event.target).data('add-url');
    }
    else {
        url = $(event.target).data('remove-url');
    }

    $.ajax({
        url: url,
        data: {
            'actionstep_pk': event.params.data.id
        },
        method: 'POST',
        success: data => {
            $(actionlist).html(data.html);
        }
    });
};

const preventSelect2FlickerOnUnselect = function preventSelect2FlickerOnUnselect () {
    // via https://github.com/select2/select2/issues/3320#issuecomment-165106221
    var $el = $('select.select2-hidden-accessible');
    $el.on('select2:unselecting', function () {
        $el.data('unselecting', true);
    }).on('select2:open', function () { // note the open event is important
        if ($el.data('unselecting')) {
            $el.removeData('unselecting'); // you need to unset this before close
            $el.select2('close');
        }
    });
};


const installSelect2PostInitHandlers = function installSelect2PostInitHandlers () {
    preventSelect2FlickerOnUnselect();
};

// bind a function that raises a browser alert on window.beforeunload event;
const bindBeforeUnload = function bindBeforeUnload () {
    $(window).bind('beforeunload', function () {
        return true;
    });
};

const preventUnloadOnEditedForms = function preventUnloadOnEditedForms (resetHandler = false) {
    if (resetHandler) {
        $(window).off('beforeunload');
    }

    if ($('body.planning form, body.broadcasts form, body.data-management form').length > 0) {
        $('form:not(#statuses-form) .form-control').on('change', function () {
            bindBeforeUnload();
        });
        $('.form-actions a.cancel').on('click', function () {
            $(window).off('beforeunload');
        });
        $('form').on('submit', function () {
            $(window).off('beforeunload');
        });
        $('a.confirm').on('click', function () {
            $(window).off('beforeunload');
        });
    }
};


const setupStickySideNav = () => {
    if ($('#follow-side-nav').length > 0) {
        const $window = $(window),
            $stickyEl = $('#follow-side-nav'),
            elTop = $stickyEl.offset().top;

        // toggle .sticky on/off to invoke stickybits on the
        // side menu
        $window.scroll(function () {
            // @@@ review Waypoint.Sticky shortcut
            // http://imakewebthings.com/waypoints/shortcuts/sticky-elements/
            $stickyEl.toggleClass('sticky', $window.scrollTop() > elTop);
        });

        stickybits('#follow-side-nav', {'stickyClass': 'stickybits-js-is-sticky'});

        // helper function to toggle QuickNav heirarchical classes
        const applyClasses = ($navLink) => {
            $navLink.addClass('active');
            if ($navLink.hasClass('top-level')) {
                $navLink.parent().find('.second-level').addClass('show');
                $navLink.parent().find('.second-level').find('.third-level').first().addClass('show');
                $navLink.parent().find('.second-level').find('.third-level').first().find('.fourth-level').first().addClass('show');
            }
            else if ($navLink.parent().parent().hasClass('second-level')) {
                // second-level li
                $navLink.closest('.second-level').addClass('show');
                $navLink.parent().next().children('.third-level').addClass('show');
                $navLink.parent().next().children('.third-level').find('.fourth-level').first().addClass('show');
            }
            else if ($navLink.parent().parent().hasClass('third-level')) {
                // third-level li
                $navLink.closest('.second-level').addClass('show');
                $navLink.closest('.third-level').addClass('show');
                $navLink.parent().next().children('.fourth-level').addClass('show');
            }
            else {
                $navLink.closest('.second-level').addClass('show');
                $navLink.closest('.third-level').addClass('show');
                $navLink.closest('.fourth-level').addClass('show');
            }
        };

        // add on-click handler for QuickNav link elements
        $stickyEl.find('a[href^="#"]').not('[href="#"]').on('click', function (e) {
            e.preventDefault();

            const clickedElem = $(this);
            // collapse heirarchy if link was clicked
            // when already active
            if (clickedElem.hasClass('active') && clickedElem.siblings('.show').length) {
                clickedElem.siblings('.show').removeClass('show');
                return;
            }

            const $anchorEl = $(`[name=${this.hash.substr(1)}]`);
            // mimic default click action:
            $('html, body').animate(
                {
                    // 1) scroll to position
                    scrollTop: $anchorEl.offset().top,
                },
                0,
                () => {
                    // 2) update styling to reflect navigation action
                    $stickyEl.find('a').each(function () {
                        $(this).removeClass('active');
                        $(this).closest('.second-level').removeClass('show');
                        $(this).closest('.third-level').removeClass('show');
                        $(this).closest('.fourth-level').removeClass('show');
                    });
                    applyClasses(clickedElem);

                    // 3) force Waypoint listeners to re-calculate
                    // based on current position
                    window.Waypoint.refreshAll();
                    // 4) set the hash value
                    window.history.replaceState(undefined, undefined, clickedElem.attr('href'));
                }
            );
        });

        // build up a list of selectors referenced by
        // QuickNav links
        const navTargetSelectors = Array();
        $stickyEl.find('a').map(function () {
            const part = `${this.hash.substr(1)}`;
            if (part.length > 0) {
                navTargetSelectors.push(`[name="${part}"]`);
            }
        });

        // instantiate Waypoint instances to trigger
        // when elements are scrolled into view
        $(navTargetSelectors.toString()).waypoint({
            continuous: false,
            offset: '10',
            handler: function () {
                $stickyEl.find('a').each(function () {
                    $(this).removeClass('active');
                    $(this).closest('.second-level').removeClass('show');
                    $(this).closest('.third-level').removeClass('show');
                    $(this).closest('.fourth-level').removeClass('show');
                });

                const $navTarget = $(`a[href="#${this.element.name}"]`);
                applyClasses($($navTarget));
            }
        });
    }
};


const setupToolTips = () => {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip();
    });
};


const setupNoScriptRedirect = () => {
    /*
    If a user is on the no script page, but has enabled JavaScript,
    redirect back to their original page.
    */
    if ($('#noscript-redirect').length > 0) {
        const redirectUrl = $('#noscript-redirect').data('redirect-url');
        if (redirectUrl) {
            window.location = redirectUrl;
        }
    }
};

export {
    addRemoveActionStep,
    bindBeforeUnload,
    installSelect2PostInitHandlers,
    preventUnloadOnEditedForms,
    setupNoScriptRedirect,
    setupStickySideNav,
    setupToolTips
};
