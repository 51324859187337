import api from '../api';
import {
    FETCH_NEEDS,
    FETCH_NEED,
    SET_LOADED,
    FILTER_ARCHIVED,
    FILTER_PRIORITY,
    FILTER_SECTIONS,
    FETCH_PERMISSIONS,
    SEARCH,
    RESET_FILTERS,
    RESET_NEED,
    SET_PRIORITY,
    SET_NON_PRIORITY,
    SET_ARCHIVED,
    SET_UNARCHIVED,
    GROUP_SELECTED_NEEDS,
    RESET_SELECTED_NEEDS,
    UNGROUP_NEED,
    TOGGLE_SELECT_NEED,
    FETCH_SECTIONS,
    SAVE_NEED,
    CREATE_NEED,
    SET_PLAN_ID,
    DELETE_NEED,
    ERROR,
    CLEAR_ERROR,
    COPY_NEED,
    MESSAGE,
    CLEAR_MESSAGE,
    FETCH_PLAN,
    FETCH_PLANS,
    SET_NEED_ORDER,
    SELECT_ALL_NEEDS,
    COPY_NEEDS
} from '../constants';

export default {
    [ERROR]: ({ commit }, { error, default_message }) => commit(ERROR, {error, default_message }),
    [CLEAR_ERROR]: ({ commit }, { index }) => commit(CLEAR_ERROR, index),
    [MESSAGE]: ({ commit }, message) => commit(MESSAGE, message),
    [CLEAR_MESSAGE]: ({ commit }, { index }) => commit(CLEAR_MESSAGE, index),
    [SET_PLAN_ID]: ({ commit }, { planId }) => commit(SET_PLAN_ID, planId),
    [FETCH_PLAN]: ({ commit }, { id }) => api.fetchPlan(id, data => commit(FETCH_PLAN, data.data)),
    [FETCH_PERMISSIONS]: ({ commit }) => api.fetchPermissions(data => commit(FETCH_PERMISSIONS, data.data)),
    [FETCH_PLANS]: ({ commit }, { id }) => api.fetchPlans(id, data => commit(FETCH_PLANS, data.data)),
    [FETCH_SECTIONS]: ({ commit }) => api.fetchSections(data => commit(FETCH_SECTIONS, data.data)),
    [FETCH_NEEDS]: ({ commit }, { id }) => {
        api.fetchNeeds(id, data => {
            commit(FETCH_NEEDS, data.data);
            commit(SET_LOADED);
        });
    },
    [FETCH_NEED]: ({ commit }, { id }) => api.fetchNeed(id, data => commit(FETCH_NEED, data.data)),
    [SET_PRIORITY]: ({ commit }, { id }) => {
        commit(RESET_NEED, id);
        api.setPriority(id, true, data => {
            commit(FETCH_NEED, data.data);
        });
    },
    [SET_NON_PRIORITY]: ({ commit }, { id }) => {
        commit(RESET_NEED, id);
        api.setPriority(id, false, data => {
            commit(FETCH_NEED, data.data);
        });
    },
    [SET_ARCHIVED]: ({ commit }, { id }) => {
        commit(RESET_NEED, id);
        api.setArchive(id, true, data => {
            commit(FETCH_NEED, data.data);
        });
    },
    [SET_UNARCHIVED]: ({ commit }, { id }) => {
        commit(RESET_NEED, id);
        api.setArchive(id, false, data => {
            commit(FETCH_NEED, data.data);
        });
    },
    [CREATE_NEED]: ({ commit }, { planId, title, priority, description, section, imageSet, supportingFile, answers }) => {
        return api.createNeed(planId, title, priority, description, section, imageSet, supportingFile, answers, data => commit(FETCH_NEED, data.data));
    },
    [SAVE_NEED]: ({ commit }, { id, title, priority, description, section, supportingFile, answers }) => {
        return api.saveNeed(id, title, priority, description, section, supportingFile, answers, data => commit(FETCH_NEED, data.data));
    },
    [DELETE_NEED]: ({ commit }, { id }) => api.deleteNeed(id, () => commit(DELETE_NEED, id)),
    [TOGGLE_SELECT_NEED]: ({ commit }, { id, isGroup }) => {
        commit(TOGGLE_SELECT_NEED, {id, isGroup});
    },
    [SELECT_ALL_NEEDS]: ({ commit }, { needs }) => commit(SELECT_ALL_NEEDS, needs),
    [FILTER_ARCHIVED]: ({ commit }, { archived }) => commit(FILTER_ARCHIVED, archived),
    [FILTER_PRIORITY]: ({ commit }, { priority }) => commit(FILTER_PRIORITY, priority),
    [FILTER_SECTIONS]: ({ commit }, { sections }) => commit(FILTER_SECTIONS, sections),
    [SEARCH]: ({ commit }, { term }) => commit(SEARCH, term),
    [RESET_FILTERS]: ({ commit }) => commit(RESET_FILTERS),
    [GROUP_SELECTED_NEEDS]: ({ state, dispatch, commit }, { title, description }) => {
        api.groupNeeds(title, description, state.selectedNeeds, state.planId, data => {
            commit(GROUP_SELECTED_NEEDS, data.data);
            commit(RESET_SELECTED_NEEDS);
            dispatch(SET_NEED_ORDER, { id: state.planId, pkList: state.needsOrder });
        });
    },
    [RESET_SELECTED_NEEDS]: ({ commit }) => commit(RESET_SELECTED_NEEDS),
    [UNGROUP_NEED]: ({ state, dispatch, commit }, { id }) => {
        api.ungroupNeed(id, data => {
            commit(UNGROUP_NEED, data.data);
            dispatch(SET_NEED_ORDER, { id: state.planId, pkList: state.needsOrder });
        });
    },
    [COPY_NEED]: ({ commit }, { id, planId }) => api.copyNeed(id, planId, data => commit(FETCH_NEED, data.data)),
    [COPY_NEEDS]: ({ state, commit }, { ids, planId, archive }) => api.copyNeeds(ids, planId, archive, data => {
        const plan = state.plans[planId];
        commit(RESET_SELECTED_NEEDS);
        commit(MESSAGE, `Needs copied to <a href="/plans/${planId}/needs/analyze/">${plan.label}</a>.`);
        if (archive) {
            commit(COPY_NEEDS, data.data);
        }
    }),
    [SET_NEED_ORDER]: ({ commit }, { id, pkList }) => api.setNeedOrder(id, pkList, data => commit(SET_NEED_ORDER, data.data))
};
