<template>
    <div class="supporting-file">
        <a :href="downloadUrl">
            <i class="fas fa-fw fa-paperclip"></i>
            {{ fileName }}
        </a>
    </div>
</template>
<script>
export default {
    name: 'supporting-file',
    props: ['fileName', 'downloadUrl']
}
</script>
