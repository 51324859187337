<template>
    <div class="box-footer" v-if="loading">
        <loading-indicator></loading-indicator>
    </div>
    <div class="box-footer" v-else>
        <span v-if="!immutable" @click.stop="$emit('selectNeed')" class="select-need">
            <i class="fas fa-fw fa-th-large not-active"></i>
            {{ selectLabel }}
        </span>

        <span v-if="selected && totalSelected > 1 && !immutable && canGroupSelections" @click.stop="$emit('groupNeeds')" class="group-needs">
            <i class="fas fa-fw fa-layer-group"></i>
            {{ groupNeedsActionLabel }}
        </span>

        <span v-if="!selected && canTogglePriority" @click.stop="$emit('togglePriority')" :class="priorityClass">
            <i :class="priorityIconClass"></i>
            <span v-if="priority">Deprioritize</span>
            <span v-else>Prioritize</span>
        </span>
        <span v-else-if="!selected && !archived" :class="priorityClass" v-b-tooltip.hover.right="priorityTitle">
            <i :class="priorityIconClass"></i>
        </span>

        <span v-if="!selected && !priority" @click.stop="$emit('toggleArchive')"
            :class="{archived: archived}">
            <i class="fas fa-fw fa-archive" :class="{'not-active': !archived}"></i>
            <span v-if="archived">Unarchive</span>
            <span v-else>Archive</span>
        </span>

        <span v-if="!selected && !archived" class="need-copy-link" @click.stop="$emit('copyNeed')">
            <i class="fas fa-fw fa-copy"></i>
            Copy
        </span>
        <span v-if="!selected && !immutable" class="need-edit-link" @click.stop="$emit('editNeed')">
            <i class="fas fa-fw fa-pencil"></i>
            Edit
        </span>
    </div>
</template>
<script>
import vBTooltip from 'bootstrap-vue/es/directives/tooltip/tooltip';

export default {
    name: 'action-bar',
    directives: {
        'b-tooltip': vBTooltip
    },
    props: [
        'canGroupSelections',
        'canTogglePriority',
        'priority',
        'archived',
        'immutable',
        'selected',
        'totalSelected',
        'changesRequired',
        'planStatus',
        'loading'
    ],
    computed: {
        selectLabel() {
            return this.selected ? 'Selected' : 'Select';
        },
        groupedNeedLabel() {
            const groupedNeedId = this.$store.state.selectedGroupNeeds[0];
            if (this.$store.state.needs[groupedNeedId] === undefined) {
                this.$store.dispatch(FETCH_NEED, { groupedNeedId });
            }
            const groupedNeed = this.$store.state.needs[groupedNeedId];
            return `${groupedNeed.title.replace(/(.{9})..+/, "$1…")}`;
        },
        groupNeedsActionLabel() {
            const selectedGroupNeedsCount = this.$store.state.selectedGroupNeeds.length;
            if (selectedGroupNeedsCount > 0) {
                const selectedCount = this.totalSelected - selectedGroupNeedsCount;
                const needLabel = selectedCount === 1 ? 'Need': 'Needs';
                return `Add ${selectedCount} ${needLabel} to "${this.groupedNeedLabel}"`;
            }
            else {
                return `Group ${this.totalSelected } Needs`;
            }
        },
        archiveClass() {
            return this.archived ? 'archived' : '';
        },
        priorityClass() {
            return this.priority ? 'priority' : '';
        },
        priorityIconClass() {
            return this.priority ? 'fas fa-fw fa-star' : 'far fa-fw fa-star not-active';
        },
        priorityTitle() {
            if (!this.canTogglePriority) {
                if (this.changesRequired) {
                    return 'Priority cannot be toggled because an approver has requested changes be made to this need'
                }
                else {
                    return `Priority cannot be toggled because plan status is "${this.planStatus}"`;
                }
            }
        }
    }
}
</script>
