<template>
    <div class="grouping-form">
        <div class="row">
            <div class="col-8">
                <div class="form">
                    <div class="form-group title">
                        <label>Need Title</label>
                        <div class="error-message text-danger" v-if="titleError">{{ titleError }}</div>
                        <input :maxlength="maxTitleLength" class="form-control" v-model="title" />
                    </div>

                    <div class="form-group description">
                        <label>Description</label>
                        <rich-editor v-model="description" :image-set="imageSet" @imageSetCreated="onImageSetCreate"></rich-editor>
                    </div>
                </div>
            </div>
            <div class="col-4" >
                <div class="selections">
                    <grouped-need v-for="n in selectedNeeds" :key="n.id" :need="n" :draft="true"></grouped-need>
                </div>
                <div class="action-group mt-4">
                    <div v-if="selectedNeeds.length > 1" >
                        <button class="btn btn-primary btn-block" @click="onGroupNeeds">
                            <i class="fas fa-fw fa-layer-group"></i>
                            Group
                        </button>
                        <button class="btn btn-secondary btn-block" @click="onCancelGrouping">Cancel</button>
                    </div>
                    <div v-else>
                        <button class="btn btn-secondary btn-block" @click="onCancelGrouping">Cancel</button>
                        <div class="text-danger mt-5">You must have at least two Needs selected.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import GroupedNeed from '../need/GroupedNeed.vue';
import { GROUP_SELECTED_NEEDS, MAX_TITLE_LENGTH } from '../../constants';
import { EditorMixin } from '../../mixins.js';
export default {
    name: 'group-need-form',
    components: { GroupedNeed },
    mixins: [EditorMixin],
    data() {
        return {
            titleError: '',
            title: '',
            description: '',
            imageSet: null
        }
    },
    computed: {
        selectedNeeds() {
            return this.$store.state.selectedNeeds.map(id => this.$store.state.needs[id]);
        },
        maxTitleLength() {
            return MAX_TITLE_LENGTH;
        }
    },
    watch: {
        title() {
            if (this.title.trim() !== '') {
                this.titleError = '';
            }
        },
    },
    methods: {
        onImageSetCreate(imageSet) {
            this.imageSet = imageSet;
        },
        resetGrouping() {
            this.titleError = '';
            this.title = '';
            this.description = '';
        },
        onCancelGrouping() {
            this.$emit('cancel');
            this.resetGrouping();
        },
        onGroupNeeds() {
            if (this.title.trim() === '') {
                this.titleError = 'Title is required.  Please provide a title.';
                return false;
            } else {
                this.titleError = '';
            }
            const payload = {
                title: this.title,
                description: this.cleanValue(this.description),
                imageSet: this.imageSet !== null ? this.imageSet.pk : null
            };
            this.$store.dispatch(GROUP_SELECTED_NEEDS, payload).then(() =>{
                this.resetGrouping();
                this.$emit('needsGrouped');
            });
        }
    }
}
</script>
