<template>
    <div class="need-form">
        <div class="form">
            <div class="form-group title">
                <label>Need Title</label>
                <div class="error-message text-danger" v-if="titleError">{{ titleError }}</div>
                <input :maxlength="maxTitleLength" class="form-control" v-model="model.title" />
            </div>
            <div v-if="!isGroupedNeed" class="form-group">
                <div><label>Section</label></div>
                <div class="error-message text-danger" v-if="sectionError">{{ sectionError }}</div>
                <div class="section-choices">
                    <div class="btn-group btn-group-toggle">
                        <label class="btn btn-light btn-section" :class="[`section-${section.slug}`, section.id === model.section ? 'active' : '']" v-for="section in sectionChoices" :key="section.id">
                            <input type="radio" v-model="model.section" :value="section.id">
                            <i></i>
                            <div>{{ section.name }}</div>
                        </label>
                    </div>
                </div>
            </div>
            <div v-if="!plan.immutable" class="form-group">
                <label>Priority Status</label>
                <div class="row ml-4 mr-4">
                    <div class="mt-2 mb-2 pt-2 pb-2 col-6 btn btn-xs btn-light" :class="{'active': !model.priority}" @click='model.priority = false'>
                        <span class="priority priority-ro">
                            <i class="far fa-fw fa-star not-active"></i>Not Prioritized
                        </span>
                    </div>
                    <div id="priorityStatus--Prioritized" class="mt-2 mb-2 pt-2 pb-2 col-6 btn btn-xs btn-light" :class="{'active': model.priority, 'priority-active': model.priority}" @click='model.priority = true'>
                        <span class="priority">
                            <i class="fas fa-fw fa-star"></i> Prioritized
                        </span>
                    </div>
                </div>
            </div>
            <div class="text-center" v-if="model.priority">
                <div class="btn-group">
                    <button class="btn btn-sm btn-light" :class="{active: showDescription}" @click.stop="visibleField = 'description';">
                        Description
                    </button>
                    <button
                        v-for="question in prioritizedNeedQuestions"
                        :key="question.id"
                        class="btn btn-sm btn-light"
                        :class="{active: isQuestionShown(question)}" @click.stop="showQuestion(question)"
                    >
                        {{ question.label }}
                    </button>
                </div>
            </div>
            <div class="form-group description" v-if="showDescription">
                <label>Description</label>
                <need-description-instructions></need-description-instructions>
                <rich-editor editor-id="description" v-model="model.description" :image-set="imageSet" @imageSetCreated="onImageSetCreate"></rich-editor>
            </div>

            <div
                v-for="question in activePrioritizedNeedQuestions"
                :key="question.id"
                :class="['form-group', question.slug]"
            >
                <label>{{ question.label }}</label>
                <div class="prioritized-need-instructions" v-if="question.question" v-html="question.question">
                </div>
                <rich-editor :editor-id="`${question.id}`" v-model="model.answers[question.id]" :image-set="imageSet" @imageSetCreated="onImageSetCreate"></rich-editor>
            </div>

            <div class="form-group">
                <label>Supporting File</label>
                <div class="supporting-file-instructions">
                    You may use the upload feature to upload any additional local data that applies to this need.
                </div>
                <div v-if="model.supportingFileName">Replace {{ model.supportingFileName }}</div>
                <b-form-file v-model="model.supportingFile"></b-form-file>
            </div>

            <div class="d-block pb-2">
                <div class="form-actions">
                    <button class="btn btn-secondary" @click="onCancel">Cancel</button>
                    <button class="btn btn-primary" @click="onSave">Save</button>
                </div>
            </div>
        </div>
        <hr v-if="hasGroupedNeeds" />
        <div class="col grouped-needs-container mt-4" v-if="hasGroupedNeeds">
            <h4>
                Grouped Needs Reference
            </h4>
            <div class="expansions mb-4">
                <button v-if="showExpandAll" @click="expandAll" class="btn btn-sm btn-primary btn-expand-all">
                    <i class="fas fa-fw fa-caret-right"></i>
                    Expand All
                </button>
                <button v-else @click="collapseAll" class="btn btn-sm btn-primary btn-collapse-all">
                    <i class="fas fa-fw fa-caret-down"></i>
                    Collapse All
                </button>
            </div>
            <grouped-need-card-list :show-all="true"  @open="g => $emit('open', g)" :grouped-needs="groupedNeeds" :read-only="true" :reference-only="true"></grouped-need-card-list>
        </div>
    </div>
</template>
<script>
import { CREATE_NEED, SAVE_NEED, MAX_TITLE_LENGTH } from '../../constants';
import { EditorMixin } from '../../mixins.js';

import NeedDescriptionInstructions from './NeedDescriptionInstructions.vue';
import GroupedNeedCardList from '../need/GroupedNeedCardList.vue';
import bFormFile from 'bootstrap-vue/es/components/form-file/form-file';
import vBToggle from 'bootstrap-vue/es/directives/toggle/toggle';

export default {
    name: 'need-form',
    mixins: [EditorMixin],
    directives: {
        'b-toggle': vBToggle
    },
    components: {
        bFormFile,
        GroupedNeedCardList,
        NeedDescriptionInstructions
    },
    props: [
        'need'
    ],
    beforeDestroy() {
        // the component is being destroyed by an explicit user action
        window.removeEventListener('beforeunload', this.preventUnload);
    },
    data() {
        return {
            sectionError: '',
            titleError: '',
            showExpandAll: true,
            visibleField: 'description',
            immutable: false,
            model: {
                section: null,
                title: '',
                priority: false,
                description: '',
                imageSet: null,
                supportingFile: null,
                supportingFileName: '',
                answers: {},
                wasChanged: null
            }
        }
    },
    methods: {
        isQuestionShown(question) {
            return this.model.priority && this.visibleField == question.id;
        },
        showQuestion(question) {
            this.visibleField = question.id;
        },
        expandAll() {
            this.$root.$emit(this.collapseGroupEvent, {'show': true});
            this.showExpandAll = false;
        },
        collapseAll () {
            this.$root.$emit(this.collapseGroupEvent, {'show': false});
            this.showExpandAll = true;
        },
        preventUnload(event) {
            event.returnValue = true;
            return event;
        },
        onImageSetCreate(imageSet) {
            this.model = {
                ...this.model,
                imageSet
            };
        },
        reset() {
            this.sectionError = '';
            this.titleError = '';
            this.visibleField = 'description';
            this.immutable = false;
            this.model = {
                section: null,
                title: '',
                priority: false,
                description: '',
                imageSet: null,
                supportingFile: null,
                supportingFileName: '',
                answers: {},
                wasChanged: null
            }
        },
        onCancel() {
            this.$emit('cancel');
            this.reset();
        },
        onSave() {
            if (!this.section && this.sectionIsRequired) {
                this.sectionError = 'Section is required.  Please make a selection.';
            } else {
                this.sectionError = '';
            }
            if (this.title.trim() === '') {
                this.titleError = 'Title is required.  Please provide a title.';
            } else {
                this.titleError = '';
            }
            if (this.sectionError || this.titleError) {
                return false;
            }

            let action = '';
            let payload = {
                title: this.model.title,
                priority: this.model.priority,
                section: this.sectionIsRequired ? this.model.section : null,
                description: this.cleanValue(this.model.description),
                planId: this.$store.state.planId,
                supportingFile: this.model.supportingFile,
                answers: [],
            };
            Object.entries(this.model.answers).forEach(([key, value]) => {
                payload.answers.push([
                    key, {answer: this.cleanValue(value)}
                ])
            });
            if (this.need) {
                // TODO: decouple payload from mutations and api;
                // lots of changes to add a single field
                action = SAVE_NEED;
                payload = {
                    ...payload,
                    id: this.need.id
                };
            }
            else {
                action = CREATE_NEED;
                payload = {
                    ...payload,
                    imageSet: this.imageSet !== null ? this.imageSet.pk : null
                };
            }

            this.$store.dispatch(action, payload).then(() => {
                this.reset();
                this.$emit('needSaved')
            });
        },
        load() {
            if (this.need) {
                this.model = {
                    ...this.model,
                    section: this.need.section,
                    title: this.need.title,
                    priority: this.need.priority,
                    description: this.need.description,
                    imageSet: this.need.imageSet,
                    supportingFileName: this.need.supportingFile,
                };
                Object.entries(this.need.answers).forEach(([key, value]) => {
                    this.model.answers[key] = value.answer;
                });
                this.immutable = this.need.immutable;
            }
            else {
                // a new need is being created, so we'll prep
                // the model watcher to consider the next change
                // an "actual" change.
                this.model.wasChanged = false;
            }
        }
    },
    created() {
        this.load();
    },
    watch: {
        section() {
            if (this.section) {
                this.sectionError = '';
            }
        },
        title() {
            if (this.title.trim() !== '') {
                this.titleError = '';
            }
        },
        need() {
            this.load();
        },
        'model.priority': function () {
            if (!this.priority) {
                this.visibleField = 'description';
            }
        },
        model: {
            handler: function () {
                if (this.model.wasChanged == false) {
                    this.model.wasChanged = true;
                    window.addEventListener('beforeunload', this.preventUnload);
                }
                else if (this.model.wasChanged == null) {
                    // the watcher is picking up the changes
                    // from `this.load()` and set `wasChanged`
                    // to true on the next "actual" change.
                    this.model.wasChanged = false;
                }
            },
            deep: true
        }
    },
    computed: {
        showDescription() {
            return this.visibleField == 'description';
        },
        plan() {
            return this.$store.getters.currentPlan;
        },
        prioritizedNeedQuestions() {
            return this.model.priority ? this.plan.prioritizedNeedQuestions : [];
        },
        activePrioritizedNeedQuestions() {
            return this.prioritizedNeedQuestions.filter(q => this.isQuestionShown(q));
        },
        collapseGroupEvent() {
            return 'TOGGLE_COLLAPSE_GROUP_' + this.need.id;
        },
        title() {
            return this.model.title;
        },
        section() {
            return this.model.section;
        },
        imageSet() {
            return this.model.imageSet !== null ? this.model.imageSet : (this.need ? this.need.imageSet : null);
        },
        sectionChoices() {
            return this.$store.state.sectionOrder.map(id => this.$store.state.sections[id]);
        },
        isGroupedNeed(){
            return this.need ? (this.need.id && !this.need.section) : false;
        },
        groupedNeeds() {
            if (!this.need) {
                return [];
            }
            return this.need.groupedNeeds.map(id => {
                if (this.$store.state.needs[id] === undefined) {
                    this.$store.dispatch(FETCH_NEED, { id });
                }
                return id;
            });
        },
        hasGroupedNeeds() {
            return this.groupedNeeds.length > 0;
        },
        sectionIsRequired(){
            return !this.need ? true : !this.isGroupedNeed;
        },
        maxTitleLength() {
            return MAX_TITLE_LENGTH;
        }
    }
}
</script>
