export default {
    // Domain Data
    permissions: [],
    planId: null,
    plansOrder: [],
    plans: {},
    needsOrder: [],
    needs: {},
    sectionOrder: [],
    sections: {},

    // App State
    lastDeletedNeed: null,
    loaded: false,
    errors: [],
    messages: [],
    selectedNeeds: [],
    selectedGroupNeeds: [],
    filters: {
        archived: false,
        priority: null,
        sections: [],

    },
    search: ''
};
