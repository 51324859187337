<template>
    <div class="group-need-container" :class="`section-${sectionSlug}`">
        <i class="fas fa-fw"></i>
        <div class="group-need-title">
            <title-link :need-id="need.id" :title="title" @open="$emit('open')"></title-link>
        </div>
        <ungroup-need :need="need" :draft="draft" :read-only="readOnly"></ungroup-need>
    </div>
</template>
<script>
import TitleLink from './TitleLink.vue';
import UngroupNeed from './UngroupNeed.vue';

export default {
    name: 'grouped-need',
    props: ['need', 'draft', 'readOnly'],
    components: { TitleLink, UngroupNeed },
    computed: {
        title() {
            return this.need.title;
        },
        sectionSlug() {
            return this.need.sectionSlug;
        }
    }
}
</script>
