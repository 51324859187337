const KIND_FIELD_MAP = {
  '': '.spacer-col',
  'hyperlink': 'input[type="url"]',
  'uploaded-file': 'input[type="file"]',
};

const toggleFields = function toggleFields ($form, omit) {
  Object.keys(KIND_FIELD_MAP).forEach((key) => {
    const $element = $form.find(KIND_FIELD_MAP[key]);
    if (key === omit) {
      $element.closest('.attachment-col').removeClass('d-none');
    }
    else {
      $element.closest('.attachment-col').addClass('d-none');
    }
  });
};

const instantiateFieldToggler = function instantiateFieldToggler (element) {
  const $element = $(element);
  const $kindInput = $element.find('.kind-select');
  $kindInput.change(function () {
    toggleFields($element, $kindInput.val());
  });
  toggleFields($element, $kindInput.val());
};

const setupEvidenceAttachmentsFormset = function setupEvidenceAttachmentsFormset () {
  if ($('.attachments-formset').length > 0 ) {
    $('.attachments-formset .formset-row').formset({
      prefix: $('.attachments-formset').data('prefix'),
      addText: '<i class="fas fa-fw fa-plus"></i> Add attachment',
      addCssClass: 'add-row d-block',
      deleteText: '<i class="fas fa-trash"></i>',
      deleteCssClass: 'delete-row order-2 col-1 text-danger',
      added: function (row) {
        instantiateFieldToggler(row);
      }
    });
    $('.attachments-formset .formset-row').each(function (i, element) {
      instantiateFieldToggler(element);
    });
  }
};

const setupTurnaroundPlansFields = function setupTurnaroundPlansFields () {
    const $predefinedTitleElem = $('body.strategy-form #id_predefined_title');
    if ($predefinedTitleElem.length) {
      const predefinedTitleSelect2 = $predefinedTitleElem.select2({
          tags: true,
          placeholder: 'Choose from existing options or begin typing to add a new one',
          allowClear: true,
          selectOnClose: true, // NOTE: This was done to ensure that closing the
                               // does not discard user input
      });

      predefinedTitleSelect2.on('select2:unselect', function (e) {
          $('body.strategy-form #id_title')[0].value = '';
      });

      predefinedTitleSelect2.on('select2:select', function (e) {
          const data = e.params.data;
          const selectionIsCustom = isNaN(parseFloat(data['id']));
          if (selectionIsCustom) {
              $('body.strategy-form #id_title')[0].value = data['id'];
              // FIXME: Remove logs after writing tests
              console.log('New selection.');
          }
          else if (parseFloat(data['id']) === 0) {
              $('body.strategy-form #id_title')[0].value = data['text'];
              // FIXME: Remove logs after writing tests
              console.log('Selected the existing title');
          }
          else {
            // FIXME: Remove logs after writing tests
              console.log('Existing selection.');
          }
      });

      if (!$(predefinedTitleSelect2).val()) {
        // FIXME: Remove logs after writing tests
          console.log('No predefined title was set.');
          const $title = $('body.strategy-form #id_title')[0];
          if ($title.value) {
              // Add the title as value "0"
              const otherTitle = {
                  id: 0,
                  text: $title.value
              };
              const newOption = new Option(otherTitle.text, otherTitle.id, false, false);
              predefinedTitleSelect2.append(newOption);
              // TODO: Use a constant for '0';
              predefinedTitleSelect2.val('0');
              predefinedTitleSelect2.trigger('change');
              // FIXME: Remove logs after writing tests
              console.log('Setting user-provided value');
          }
      }
    }
};

export {
  setupEvidenceAttachmentsFormset,
  setupTurnaroundPlansFields
};
