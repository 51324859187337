const setupApprovedByForm = () => {
    $('#approved-by-form [name="approved-by"]').change(function () {
        const anchorElem = document.createElement('a');
        anchorElem.href = window.location;
        anchorElem.hash = '';

        const searchParams = new URLSearchParams();
        searchParams.set('approved-by', this.value);
        anchorElem.search = searchParams;

        window.location = anchorElem.href;
    });
};

export default setupApprovedByForm;
