<template>
    <span @click="onClick" :class="{active: active}" class="filter-toggle-menu-item">
        <span class="fas fa-fw fa-check"></span>
        <slot></slot>
    </span>
</template>
<script>
export default {
    name: 'filter-toggle-menu-item',
    props: [
        'item',
        'active'
    ],
    methods: {
        onClick() {
            this.$emit('selected', this.item);
        }
    }
}
</script>
