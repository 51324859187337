const setupPlannerFeedbackStatusForm = () => {
    $('#planner-feedback-status-form [name="feedback-status"]').change(function () {
        const anchorElem = document.createElement('a');
        anchorElem.href = window.location;
        anchorElem.hash = '';

        const searchParams = new URLSearchParams();
        searchParams.set('status', this.value);
        anchorElem.search = searchParams;

        window.location = anchorElem.href;
    });
};

export default setupPlannerFeedbackStatusForm;
