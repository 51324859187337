import HTTP from './http';

export default {
    fetchSections: cb => HTTP.get('sections/', cb),
    fetchPermissions: cb => HTTP.get('permissions/', cb),
    fetchNeeds: (id, cb) => HTTP.get(`plans/${id}/needs/`, cb),
    fetchNeed: (id, cb) => HTTP.get(`needs/${id}/`, cb),
    fetchPlan: (id, cb) => HTTP.get(`plans/${id}/`, cb),
    fetchPlans: (id, cb) => HTTP.get(`plans/${id}/other-plans/`, cb),
    createNeed: (planId, title, priority, description, section, imageSet, supportingFile, answers, cb) => {
        const data = new FormData();
        data.append('title', title);
        data.append('priority', priority);
        data.append('description', description);
        data.append('section', section);
        if (imageSet) {
            data.append('imageSet', imageSet);
        }
        if (supportingFile) {
            data.append('supportingFile', supportingFile, supportingFile.name);
        }
        answers.forEach(([id, answerObject]) => {
            data.append(`answer_${id}`, answerObject.answer);
        });
        const headers = { 'ContentType': 'multipart/form-data' };
        return HTTP.post(`plans/${planId}/needs/`, cb, data, { headers });
    },
    saveNeed: (id, title, priority, description, section, supportingFile, answers, cb) => {
        const data = new FormData();
        data.append('title', title);
        data.append('priority', priority);
        data.append('description', description);
        if (section) {
            data.append('section', section);
        }
        if (supportingFile) {
            data.append('supportingFile', supportingFile, supportingFile.name);
        }
        answers.forEach(([id, answerObject]) => {
            data.append(`answer_${id}`, answerObject.answer);
        });
        const headers = { 'ContentType': 'multipart/form-data' };
        return HTTP.post(`needs/${id}/update/`, cb, data, { headers });
    },
    deleteNeed: (id, cb) => HTTP.post(`needs/${id}/delete/`, cb),
    setPriority: (id, set, cb) => HTTP.post(`needs/${id}/${set ? 'set-priority' : 'unset-priority'}/`, cb),
    setArchive: (id, set, cb) => HTTP.post(`needs/${id}/${set ? 'archive' : 'unarchive'}/`, cb),
    groupNeeds: (title, description, needs, planId, cb) => HTTP.post('needs/group/', cb, { title, description, needs, planId }),
    ungroupNeed: (id, cb) => HTTP.post(`needs/${id}/ungroup/`, cb),
    copyNeed: (id, planId, cb) => HTTP.post(`needs/${id}/copy/`, cb, { plan: planId }),
    copyNeeds: (ids, planId, archive, cb) => HTTP.post('needs/copy/', cb, { planId, needs: ids, archive: !!archive }),
    uploadImage: (id, file, cb) => {
        const url = id ? `image-sets/${id}/` : 'image-sets/';
        const formData = new FormData();
        formData.append('files', file);
        return HTTP.post(url, cb, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    setNeedOrder: (id, pkList, cb) => HTTP.post(`plans/${id}/needs/set-order/`, cb, { pkList })
};
