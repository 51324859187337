<template>
    <span v-if="date" class="pretty-date" :title="date">{{ formattedDate }}</span>
</template>
<script>
import { format } from 'date-fns';

export default {
    name: 'pretty-date',
    props: ['date'],
    computed: {
        formattedDate() {
            return format(this.date, 'MMM dd');
        }
    }
}
</script>

