const prefetchDefaults = function (url) {
    return {
        url: url,
        cache: false,
        transform: (response) => {
            return $.map(response, (val, i) => {
                // store the position of the data
                // as returned from the server
                val.position = i;
                return val;
            });
        }
    };
};

const sorterDefaults = function (a, b) {
    // use the position of the data as
    // returned from the server for
    // sorting results
    return a.position - b.position;
};

const showAllResultsForEngine = function (engine) {
    return (q, sync) => {
        if (q === '') {
            // ensure consistent sorting of results
            const sorted_results = engine.sorter(engine.all());
            sync(sorted_results);
        }
        else {
            engine.search(q, sync);
        }
    };
};

export {
    showAllResultsForEngine,
    prefetchDefaults,
    sorterDefaults
};
